import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, TextField, Grid, Autocomplete } from '@mui/material';
import useUserApi from '../hooks/api/useUserApi';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { premiumUsersSelector } from '../redux/selectors/userSelector';
import AppLoader from './AppLoader';
import usePractitionerApi from '../hooks/api/usePractitionerApi';
import { practitionersSelector } from '../redux/selectors/practitionerSelector';
import { PractitionerModule } from '../modules/Practitioner';

const AdminDashboard: React.FC = () => {
    const premiumUsers = useAppSelector(premiumUsersSelector);
    const practitioners = useAppSelector(practitionersSelector);
    const [loadingPremiumUsers, setLoadingPremiumUsers] = useState(false);
    const [loadingPractitioners, setLoadingPractitioners] = useState(false);
    const [syncPractitionerId, setSyncPractitionerId] = useState<number>(0);
    const [syncPractitionerEmail, setSyncPractitionerEmail] = useState('');
    const [savingSyncPractitioner, setSavingSyncPractitioner,] = useState(false);

    const { fetchPremiumUsers } = useUserApi();
    const { fetchPractitioners } = usePractitionerApi();
    const { syncExistingPractitionerToModifications } = usePractitionerApi();

    useEffect(() => {
        const fetchData = async () => {
            setLoadingPremiumUsers(true);
            await fetchPremiumUsers().finally(() => {
                setLoadingPremiumUsers(false);
            });
            setLoadingPractitioners(true);
            await fetchPractitioners().finally(() => {
                setLoadingPractitioners(false);
            })
        }
        fetchData();
    }, []);

    const handleSyncData = async () => {
        setSavingSyncPractitioner(true);
        await syncExistingPractitionerToModifications({ email: syncPractitionerEmail, existingPractitionerId: syncPractitionerId });
        setSavingSyncPractitioner(false);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Admin Dashboard
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <div>
                        <Typography variant="h6">Total Premium Members</Typography>
                        {/* Add logic to fetch and display total premium members */}
                        <Typography variant="body1">{loadingPremiumUsers ? <AppLoader /> : premiumUsers?.length}</Typography>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div>
                        <Typography variant="h6">Sync Practitioner Data</Typography>
                        <br />
                        <Autocomplete
                            options={practitioners}
                            getOptionLabel={(option: PractitionerModule) => option?.fullName}
                            filterSelectedOptions
                            // disableClearable
                            onChange={(event, newValue: any) => {
                                setSyncPractitionerId(Number(newValue?.id));
                            }}
                            loading={loadingPractitioners || !(practitioners?.length > 0)}
                            isOptionEqualToValue={(option: any, value: any) => option?.id == value?.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={syncPractitionerId}
                                    // type='number'
                                    label="Practitioner"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                        <br />
                        <TextField
                            type="email"
                            label="Email"
                            variant="outlined"
                            value={syncPractitionerEmail}
                            onChange={(e) => setSyncPractitionerEmail(e.target.value)}
                            fullWidth
                        />
                        <br />
                        <br />
                        <Button variant="contained" color="primary" disabled={!(syncPractitionerId > 0) || !syncPractitionerEmail || savingSyncPractitioner} onClick={handleSyncData}>
                            Sync Data
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AdminDashboard;