import React, { useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop';
import { AvatarPropsVariantOverrides } from '@mui/material/Avatar';
import { canvasPreview } from './canvasPreview'
import useDebounceEffect from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css';
import { Avatar, Typography, Alert, AlertTitle, Fade, ImageList, ImageListItem, useMediaQuery, Grid } from '@mui/material';
import AppLoader from '../AppLoader';

interface CropImageProps {
    handleUploadImage: (croppedImageFile: File | null) => void,
    handleSelectImageFromGalleryImage?: (image: string) => void,
    existingImage: string | undefined,
    disabled?: boolean,
    uploadButtonText?: string | undefined,
    imageButtonPreviewVariant?: OverridableStringUnion<
        'circular' | 'rounded' | 'square',
        AvatarPropsVariantOverrides
    >
    cropAspect?: number,
    minHeight?: number,
    galleryImageUrls?: string[],
}

const CropImage: React.FC<CropImageProps> = ({ handleUploadImage, existingImage, disabled = false, uploadButtonText = 'Upload Profile Image', imageButtonPreviewVariant = 'circular',
                                                cropAspect = 1, minHeight = 600, galleryImageUrls = [], handleSelectImageFromGalleryImage}) => {
    // const [image, setImage] = useState<string | null>(null);
    // const aspect: number = 1;

    // const [crop, setCrop] = useState<Crop>({ x: 0, y: 0, width: minWidth, height: minHeight, unit: 'px'});
    // const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const blobUrlRef = useRef('')
    const inputFileRef = useRef<HTMLInputElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(cropAspect)

    const [open, setOpen] = useState(false);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [loadedImageWidth, setLoadedImageWidth] = useState<number>(0);
    const [loadedImageHeight, setLoadedImageHeight] = useState<number>(0);
    const minWidth: number = Math.ceil(minHeight * cropAspect);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSavingGallerySelection, setIsSavingGallerySelection] = useState<boolean>(false);
    const [cropImageAlertVisibility, setCropImageAlertVisibility] = useState(false);
    const cropMinWidth = minWidth * ((imgRef?.current?.width ?? loadedImageWidth) / loadedImageWidth);
    const cropMinHeight = minHeight * ((imgRef?.current?.height ?? loadedImageHeight) / loadedImageHeight);


    const handleClose = () => {
        setOpen(false);
        resetFileInput();
    };

    const handleGalleryClose = () => {
        setGalleryOpen(false);
    };

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            setLoadedImageWidth(0);
            setLoadedImageHeight(0);
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                const readerImgSrc: string = reader.result?.toString() || ''
                setImgSrc(readerImgSrc);
                var image = new Image();
                image.src = readerImgSrc;
                image.onload = function () {
                    setLoadedImageWidth(image.width);
                    setLoadedImageHeight(image.height);
                    if(image.width < minWidth || image.height < minHeight) {
                        setCropImageAlertVisibility(true);
                        resetFileInput();
                    } else {
                        setCropImageAlertVisibility(false);
                    }
                };
            })
            reader.readAsDataURL(e.target.files[0]);
            setGalleryOpen(false);
            setOpen(true);
        }
    }

    const resetFileInput = () => {
        if(inputFileRef?.current) {
            inputFileRef.current.value = '';
        }
    }

    const centerAspectCrop = (
        mediaWidth: number,
        mediaHeight: number,
        aspect: number) => {
        {
            return centerCrop(
                makeAspectCrop(
                    {
                        unit: '%',
                        width: mediaWidth > minWidth && mediaHeight > minHeight ? 90 : 100,
                    },
                    aspect,
                    mediaWidth,
                    mediaHeight,
                ),
                mediaWidth,
                mediaHeight,
            )
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    async function getCroppedFile() {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
            type: 'image/png',
        })

        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current)
        }
        blobUrlRef.current = URL.createObjectURL(blob)

        var file = new File([blob], 'practitioner_modification_display_img');
        setCroppedImage(URL.createObjectURL(file));
        return file;

    }

    const uploadCroppedImage = async () => {
        setIsSaving(true);
        var file = await getCroppedFile();
        await handleUploadImage(file);
        setIsSaving(false);
        setOpen(false);
        setCroppedImage(null);
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                );
                // get the file only to set the cropped img url
                getCroppedFile();
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else {
            setAspect(cropAspect)

            if (imgRef.current) {
                const { width, height } = imgRef.current
                const newCrop = centerAspectCrop(width, height, cropAspect)
                setCrop(newCrop)
                // Updates the preview
                setCompletedCrop(convertToPixelCrop(newCrop, width, height))
            }
        }
    }

    const handleUiButtonClick = () => {
        if(!disabled && galleryImageUrls?.length > 0) {
            setGalleryOpen(true);
        } else {
            return;
        }
    }
    const mobileGallery = useMediaQuery('(max-width:600px)');

    const handleSelectImageFromGalleryImageFunction = async (image: string) => {
        if(!isSavingGallerySelection && handleSelectImageFromGalleryImage) {
            setIsSavingGallerySelection(true);
            await handleSelectImageFromGalleryImage(image);
            setGalleryOpen(false);
            setIsSavingGallerySelection(false);
        }
    }
    

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                id="upload-button"
                style={{ display: 'none' }}
                disabled={disabled}
                ref={inputFileRef}
            />
            {cropImageAlertVisibility && (<Fade
                in={cropImageAlertVisibility}
                timeout={{ enter: 1000, exit: 1000 }}
                addEndListener={() => {
                    setTimeout(() => {
                        setCropImageAlertVisibility(false)
                    }, 5000);
                }}
            >
                <Alert severity="error" variant="standard" onClose={() => { setCropImageAlertVisibility(false) }}>
                    <AlertTitle>Error</AlertTitle>
                    Image must be at least {minWidth}x{minHeight} pixels
                </Alert>
            </Fade>)}
            <Typography component={'label'} htmlFor={galleryImageUrls?.length > 0 ? '' : "upload-button"} onClick={handleUiButtonClick}>
                <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0px' }}>
                    <Button variant="contained" component="span" disabled={disabled}>
                        {uploadButtonText}
                    </Button>
                    {existingImage && <Avatar src={existingImage} variant={imageButtonPreviewVariant} alt="Practitioner-Modification-Display-Image" style={{ marginLeft: '20px' }} />}
                </div>
            </Typography>

            {galleryImageUrls?.length > 0 && (
                <Dialog maxWidth="xl" open={galleryOpen} onClose={handleGalleryClose}>
                    <DialogTitle>{uploadButtonText}{isSavingGallerySelection && <AppLoader />}</DialogTitle>
                    <DialogContent>
                        <Typography variant='h6' style={{ float: 'left' }}>Select from Gallery, Or...</Typography>
                        <label htmlFor="upload-button" style={{ float: 'right', marginTop: '-20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0px' }}>
                                <Grid  spacing={1}>
                                    <Grid item xs={12}>
                                        <Button variant="contained" component="span" disabled={disabled || isSavingGallerySelection}>
                                            Upload your own image
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component={'label'}>Please only upload public domain/your own images</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </label>

                        <br />

                        <ImageList sx={{ maxWidth: 600, width: '100%', height: 'auto' }} cols={mobileGallery ? 2 : 3} rowHeight={164} gap={5}>
                            {galleryImageUrls.map((item, index) => (
                                <ImageListItem key={item} style={{ cursor: 'pointer' }} onClick={async() => await handleSelectImageFromGalleryImageFunction(item)}>
                                    <img
                                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                        alt={`gallery display image image ${index}`}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </DialogContent>
                </Dialog>
            )}

            {!!imgSrc && (
                <Dialog fullWidth maxWidth="xl" open={open && loadedImageWidth >= minWidth && loadedImageHeight >= minHeight} onClose={handleClose}>
                    <DialogTitle>Crop Image</DialogTitle>
                    <DialogContent>
                        {/* <div>
                            <label htmlFor="scale-input">Scale: </label>
                            <input
                                id="scale-input"
                                type="number"
                                step="0.1"
                                value={scale}
                                disabled={!imgSrc}
                                onChange={(e) => setScale(Number(e.target.value))}
                            />
                        </div>
                        <div>
                            <label htmlFor="rotate-input">Rotate: </label>
                            <input
                                id="rotate-input"
                                type="number"
                                value={rotate}
                                disabled={!imgSrc}
                                onChange={(e) =>
                                    setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                                }
                            />
                        </div>
                        <div>
                            <button onClick={handleToggleAspectClick}>
                                Toggle aspect {aspect ? 'off' : 'on'}
                            </button>
                        </div> */}
                        <div /*style={{ overflow: 'scroll', minWidth: loadedImageWidth > 0 ? loadedImageWidth : minWidth }}*/>
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                                minWidth={cropMinWidth}
                                minHeight={cropMinHeight}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop Image"
                                    src={imgSrc}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)`/*, minHeight: loadedImageHeight > 0 ? loadedImageHeight : minHeight*/ }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </div>

                        {!!completedCrop && (
                            <>
                                <div>
                                    <canvas
                                        hidden
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {croppedImage && <Typography variant="h5" component="h5">Avatar: <Avatar src={croppedImage} alt="Cropped-Avatar" /></Typography>}
                        {croppedImage && <Typography variant="h5" component="h5">Display Image: <br /><img src={croppedImage} alt="Cropped-Profile-Display-Image" style={{ height: '333px', maxWidth: '400px', objectFit: 'cover' }} /></Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isSaving} onClick={uploadCroppedImage} color="primary" variant="contained">
                            Upload
                        </Button>
                        <Button disabled={isSaving} onClick={handleClose} color="primary" variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    )

    /*

  const handleOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
      setImage(null);
      setCroppedImage(null);
      setCroppedImageFile(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
          const reader = new FileReader();
          reader.onload = () => {
              setImage(reader.result as string);
              setOpen(true);
          };
          reader.readAsDataURL(file);
      }
  };

  const handleCropChange = (newCrop: Crop) => {
      setCrop(newCrop);
  };

  const handleCropComplete = async (pixelCrop: PixelCrop) => {
      if (image) {
          const croppedImageFile = await getCroppedImg(image, pixelCrop);
          setCroppedImageFile(croppedImageFile);
          setCroppedImage(URL.createObjectURL(croppedImageFile));
      }
  };

  const uploadCroppedImage = async () => {
      if (croppedImage) {
          setIsSaving(true);
          await handleUploadImage(croppedImageFile);
          setIsSaving(false);
      }
      setOpen(false);
      setImage(null);
      setCroppedImage(null);
      setCroppedImageFile(null);
  };

  return (
      <div>
          <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              id="upload-button"
              style={{ display: 'none' }}
              disabled={disabled}
          />
          <label htmlFor="upload-button">
              <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0px' }}>
                  <Button variant="contained" component="span" disabled={disabled}>
                      Upload Profile Image
                  </Button>
                  {existingImage && <Avatar src={existingImage} alt="Practitioner-Modification-Display-Image" style={{ marginLeft: '20px'}}/>}
              </div>
          </label>
          <Dialog maxWidth={'xl'} open={open} onClose={handleClose}>
              <DialogTitle>Crop Profile Image</DialogTitle>
              <DialogContent>
                  {image && (
                      <ReactCrop
                          aspect={aspect}
                          minHeight={minHeight}
                          minWidth={minWidth}
                          crop={crop}
                          onChange={handleCropChange}
                          onComplete={handleCropComplete}
                      >
                          <img src={image} />
                      </ReactCrop>
                  )}
                  {croppedImage && <Typography variant="h5" component="h5">Avatar: <Avatar src={croppedImage} alt="Cropped-Avatar" /></Typography>}
                  {croppedImage && <Typography variant="h5" component="h5">Profile Page Display Image: <img src={croppedImage} alt="Cropped-Profile-Display-Image" style={{ height: '333px', width: '100%', objectFit: 'cover' }} /></Typography>}
              </DialogContent>
              <DialogActions>
                  <Button disabled={isSaving} onClick={uploadCroppedImage} color="primary" variant="contained">
                      Upload
                  </Button>
                  <Button disabled={isSaving} onClick={handleClose} color="primary" variant="contained">
                      Cancel
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
  );
  */
};


/*
const getCroppedImg = async (image: string, pixelCrop: PixelCrop) => {
  const imageElement = document.createElement('img');
  imageElement.src = image;

  const scaleX = imageElement.naturalWidth / imageElement.width;
  const scaleY = imageElement.naturalHeight / imageElement.height;

  const canvas = document.createElement('canvas');

  const ctx = canvas.getContext('2d');
  if (!ctx) {
      throw new Error('Canvas context not available');
  }

  // const pixelRatio = window.devicePixelRatio;
  canvas.width = pixelCrop.width;// * pixelRatio;
  canvas.height = pixelCrop.height;// * pixelRatio;
  // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";
  ctx.drawImage(
      imageElement,
      (pixelCrop.x! * scaleX),
      (pixelCrop.y! * scaleY),
      pixelCrop.width! * scaleX,
      pixelCrop.height! * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
  );

  return new Promise<File>((resolve) => {
      canvas.toBlob((blob) => {
          if (!blob) {
              throw new Error('Could not create a blob');
          }
          var file = new File([blob], 'practitioner_modification_display_img');
          resolve(file);
          // resolve(URL.createObjectURL(blob));
      }, 'image/jpeg');
  });
};
*/

export default CropImage;
