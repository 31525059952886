import { Reducer } from 'redux';
import { SET_CATEGORIES } from "../constants";
import { CategoryModule } from "../../modules/Category";

interface CategoryState  {
  categories: CategoryModule[];
  }

const initialState: CategoryState = {
    categories: [],
  };
  
  const CategoryReducer: Reducer<CategoryState> = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_CATEGORIES:
          return {
            ...state,
            categories: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default CategoryReducer;
  