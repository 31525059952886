import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid, TablePagination, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { practiceModificationsSelector } from '../redux/selectors/practiceSelector';
import { PracticeModificationModule, PracticeModificationStatus } from '../modules/PracticeModification';
import usePracticeApi from '../hooks/api/usePracticeApi';
import { truncateString } from '../utils/stringUtils';
import ImageIcon from '@mui/icons-material/Image';
import AppLoader from './AppLoader';
import SEO from './SEO';
import { isAdminSelector } from '../redux/selectors/authSelector';

const Practices: React.FC = () => {

    const { fetchPracticeModifications } = usePracticeApi();

    const [isLoading, setIsLoading] = useState(false);

    const isAdmin = useAppSelector(isAdminSelector);

    const practiceModifications = useAppSelector(practiceModificationsSelector);
    useEffect(() => {
        const fetchData = async () => {
            console.log('fetching data..');
            setIsLoading(true);
            await fetchPracticeModifications().then((data: PracticeModificationModule[]) => {
                setIsLoading(false);
            })
        }
        fetchData();
    }, []);

    // search
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState<PracticeModificationStatus | 'All'>(isAdmin ? PracticeModificationStatus.SUBMITTED : 'All');
    const filteredPractices = practiceModifications.filter((practiceModification: PracticeModificationModule) =>
        (!searchQuery || practiceModification?.title?.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (statusFilter === 'All' || (practiceModification?.status === statusFilter))
    );

    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const paginatedPractices = filteredPractices.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    if (isLoading) {
        return (
            <AppLoader />
        );
    }


    return (
        <div>
            <SEO
                title='Practices'
                description='View all your practices status'
            />
            <h1>Practices</h1>
            <div style={{ textAlign: 'right' }}>
                <Link to={!isAdmin ? "/practice" : "#"}>
                    <Button variant="contained" color="primary" disabled={isAdmin}>
                        Create a new practice
                    </Button>
                </Link>
            </div>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <TextField
                        margin='normal'
                        label="Search by Title"
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => { setPage(0); setSearchQuery(e.target.value); }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl style={{ minWidth: '100%', marginTop: '16px' }}>
                        <InputLabel id="status-filter-label">Status</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="status-filter-label"
                            label="Status"
                            id="status-filter"
                            value={statusFilter}
                            onChange={(e) => { setPage(0); setStatusFilter(e.target.value as PracticeModificationStatus | 'All'); }}
                        >
                            <MenuItem key="All" value="All">All</MenuItem>
                            {Object.keys(PracticeModificationStatus).filter(p => isNaN(Number(p))).map((statusKey: any) => (
                                <MenuItem key={statusKey} value={PracticeModificationStatus[statusKey]}>
                                    {statusKey?.replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <List>
                {paginatedPractices?.map((practiceModification: PracticeModificationModule, index: number) => (
                    <ListItem key={practiceModification.id + '_' + index} button component={Link} to={`/practice/${practiceModification.id}`}>
                        <Grid container spacing={0}>
                            <Grid item md={1}>
                                <ListItemAvatar>
                                    <Avatar alt={practiceModification.title} variant='square'
                                        src={practiceModification?.displayImgUrl ?? practiceModification?.existingPractice?.displayImgUrl}>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                {isAdmin && (<>
                                    {practiceModification?.practitioner?.fullName} {practiceModification?.practitioner?.id}
                                </>)}
                            </Grid>
                            <Grid item md={2}>
                                <ListItemText primary={PracticeModificationStatus[practiceModification?.status]?.replaceAll('_', ' ')} />
                            </Grid>
                            <Grid item md={2}>
                                <ListItemText primary={practiceModification?.existingPracticeId && practiceModification.existingPracticeId > 0 ? 'PUBLISHED' : 'UNPUBLISHED'} />
                            </Grid>
                            <Grid item md={7}>
                                {(isAdmin && practiceModification?.miniCourseModification) && (<>
                                    Mini Course: {practiceModification?.miniCourseModification?.title} {practiceModification?.miniCourseModification?.id}
                                </>)}
                                <ListItemText primary={practiceModification.title} secondary={truncateString(practiceModification.description, 300)} />
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
            <TablePagination
                component="div"
                variant='footer'
                count={filteredPractices.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default Practices;