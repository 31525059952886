import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({ title = '', description, name = 'SomaShare LLC', type = 'website', thumbnail }: any) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title ? title + ' - ' : ''}SomaShare Practitioner Dashboard</title>
            <meta name='description' content={description} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={'SomaShare Practitioner Dashboard - ' + title} />
            <meta property="og:description" content={description} />
            {thumbnail && <meta property="og:image" content={thumbnail} />}
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={'SomaShare Practitioner Dashboard - ' + title} />
            <meta name="twitter:description" content={description} />
            { /* End Twitter tags */}
        </Helmet>
    )
}