import { PractitionerModificationModule } from "../../modules/PractitionerModification";
import {
  setPractitionerModification,
  setPractitionerModificationDisplayImage,
  setPractitionerModifications,
  setPractitioners,
} from "../../redux/actions/practitionerActions";
import { useAppDispatch } from "../app/useAppDispatch";
import useApi from "./useApi";

const usePractitionerApi = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  return {
    fetchPractitioners: async () => {
      try {
        const response = await api.get(
          "Practitioner/GetPractitioners"
        );
        const practitioners = response.data;
        dispatch(setPractitioners(practitioners));
        return practitioners;
      } catch (error) {
        console.error("Error fetching practitioners data:", error);
      }
    },
    
    fetchPractitionerModifications: async () => {
      try {
        const response = await api.get(
          "Practitioner/GetPractitionerModifications"
        );
        const practitionerModifications = response.data;
        dispatch(setPractitionerModifications(practitionerModifications));
        return practitionerModifications;
      } catch (error) {
        console.error("Error fetching practitioner modifications data:", error);
      }
    },

    fetchPractitionerModification: async (id?: number | undefined | null) => {
      try {
        const response = await api.get(
          id && id > 0
            ? `Practitioner/GetPractitionerModification/${id}`
            : "Practitioner/GetPractitionerModification"
        );
        const practitionerModification = response.data;
        dispatch(setPractitionerModification(practitionerModification));
        return practitionerModification;
      } catch (error) {
        console.error("Error fetching practitioner modification data:", error);
      }
    },

    fetchPractitionerModificationDisplayImage: async (id?: number | undefined | null) => {
      try {
        const response = await api.get(
          id && id > 0
            ? `Practitioner/GetPractitionerModification/${id}`
            : "Practitioner/GetPractitionerModification"
        );
        const practitionerModification = response.data;
        dispatch(setPractitionerModificationDisplayImage(practitionerModification));
        return practitionerModification;
      } catch (error) {
        console.error("Error fetching practitioner modification data:", error);
      }
    },

    savePractitionerModification: async (
      practitionerModificationData: PractitionerModificationModule
    ) => {
      try {
        const response = await api.post(
          "Practitioner/SavePractitionerModification",
          practitionerModificationData
        );
      } catch (error) {
        console.error("Error saving practitioner modification:", error);
      }
    },

    updatePractitionerModificationAvatar: async (file: FormData) => {
      try {
        const response = await api.post(
          "Practitioner/UpdatePractitionerModificationAvatar",
          file
        );
      } catch (error) {
        console.error(
          "Error updating practitioner modification avatar:",
          error
        );
      }
    },

    reviewPractitionerModification: async (
      reviewPractitionerModificationData: any
    ) => {
      try {
        await api.post(
          "Practitioner/ReviewPractitionerModification",
          reviewPractitionerModificationData
        );
      } catch (error) {
        console.error("Error reviewing practitioner modification:", error);
      }
    },

    approvePractitionerModification: async (
      approvePractitionerModificationData: any
    ) => {
      try {
        await api.post(
          "Practitioner/ApprovePractitionerModification",
          approvePractitionerModificationData
        );
      } catch (error) {
        console.error("Error approving practitioner modification:", error);
      }
    },

    rejectPractitionerModification: async (
      rejectPractitionerModificationData: any
    ) => {
      try {
        await api.post(
          "Practitioner/RejectPractitionerModification",
          rejectPractitionerModificationData
        );
      } catch (error) {
        console.error("Error rejecting practitioner modification:", error);
      }
    },

    syncExistingPractitionerToModifications: async (
      syncExistingPractitionerToModificationsData: { email: string, existingPractitionerId: number }
    ) => {
      try {
        const response = await api.post(
          "Practitioner/SyncExistingPractitionerToModifications",
          syncExistingPractitionerToModificationsData
        );
      } catch (error) {
        console.error("Error syncing practitioner modification data:", error);
      }
    },
  };
};

export default usePractitionerApi;
