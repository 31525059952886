import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
      primary: {
        main: '#7C739D',
      },
      secondary: {
        main: '#F5CF67',
      },
      error: {
        main: "#ff604f"
      },
    },
    typography: {
      h1: {
        color: '#1A144A',
      },
      h2: {
        color: '#1A144A',
      },
      h3: {
        color: '#1A144A',
      },
      h4: {
        color: '#1A144A',
      },
      h5: {
        color: '#1A144A',
      },
      h6: {
        color: '#1A144A',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 24, // Set the border radius for all buttons
            color: '#FFFFFF',
            fontSize: 16,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          },
        },
      },
    },
  });
  
  export default theme;