import { SET_AUTH_TOKEN, SET_ROLES } from "../constants";

export const setAuthToken = (token: string | null) => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const setAuthRoles = (roles: string[]) => ({
  type: SET_ROLES,
  payload: roles,
});
