// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';

// interface AuthenticatedRouteProps {
//   role: string; // Role required to access the route
//   children: any,
// }


// const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ role, children }): React.ReactElement => {
//   const { isAuthenticated, user } = useAuth0();

//   if (!isAuthenticated) {
//     return <Navigate to="/sign-in" />;
//   }
//   if (user && user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']?.includes(role)) {
//     return children
//   }

//   return <Navigate to="/unauthorized" />;
// };

// export default AuthenticatedRoute;



// import React, { useEffect, useState } from 'react';
// import { Route, Navigate, RouteProps as ReactRouteProps } from 'react-router-dom';
// import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
// import AppLoader from './AppLoader';
// import { WithRoleBasedRedirect } from './WithAuthenticationRequired';

// type AuthenticatedRouteProps = ReactRouteProps & {
//   component: any;
//   roles: string[];
// }

// const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
//   component,
//   roles,
//   ...rest
// }) => {
//   const { user, getAccessTokenSilently } = useAuth0();
//   const [isAccessTokenValid, setIsAccessTokenValid] = useState(false);

//   useEffect(() => {
//     const checkAuthentication = async () => {
//       try {
//         // Use getAccessTokenSilently to refresh the authentication state
//         await getAccessTokenSilently();
//         setIsAccessTokenValid(true);
//       } catch (error) {
//         setIsAccessTokenValid(false);
//       }
//     };

//     checkAuthentication();
//   }, [getAccessTokenSilently]);

//   const checkRoles = () => {
//     const userRoles = user?.['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || [];
//     return roles.every((role) => userRoles.includes(role));
//   };

//   const Cp = withAuthenticationRequired(WithRoleBasedRedirect(component, { roles }),
//   {
//     onRedirecting: () => <AppLoader />,
//   }
//   )}

//   return !checkRoles() ? <Navigate to="/sign-in" /> : <Cp {...rest} />
// };

// export default AuthenticatedRoute;

import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppLoader from "./AppLoader";
import { withRoleBasedRedirect } from "./withRoleBasedRedirect";

export const AuthenticatedRoute = ({ component, roles, superRoles, ...args }: any) => {
  let Component = withAuthenticationRequired(component, {
    onRedirecting: () => <AppLoader />,
  });
  if(roles) {
    Component = withAuthenticationRequired(
      withRoleBasedRedirect(component, { roles, superRoles }),
      {
        onRedirecting: () => <AppLoader />,
      }
    )
  }
  return <Component {...args}/>
};