// practiceActions.js
import axios from "axios";
import { SET_PRACTICE_MODIFICATION, SET_PRACTICE_MODIFICATIONS, SET_PRACTICE_DISPLAY_IMAGE_PUBLIC_IMAGES, SET_MINI_COURSE_MODIFICATION, SET_MINI_COURSE_MODIFICATIONS, SET_PRACTICE_MODIFICATION_DISPLAY_IMAGE, SET_MINI_COURSE_MODIFICATION_DISPLAY_IMAGE, SET_PRACTICE_MODIFICATION_AUDIO } from "../constants";
import { PracticeModificationModule } from "../../modules/PracticeModification";
import { MiniCourseModificationModule } from "../../modules/MiniCourseModification";

interface SetPracticeModificationAction {
  type: typeof SET_PRACTICE_MODIFICATION;
  payload: PracticeModificationModule | null;
}

export const setPracticeModification = (
  data: PracticeModificationModule | null
): SetPracticeModificationAction => ({
  type: SET_PRACTICE_MODIFICATION,
  payload: data,
});

interface SetPracticeModificationDisplayImageAction {
  type: typeof SET_PRACTICE_MODIFICATION_DISPLAY_IMAGE;
  payload: PracticeModificationModule | null | undefined;
}

export const setPracticeModificationDisplayImage = (
  data: PracticeModificationModule | null | undefined,
): SetPracticeModificationDisplayImageAction => ({
  type: SET_PRACTICE_MODIFICATION_DISPLAY_IMAGE,
  payload: data,
});

interface SetPracticeModificationAudioAction {
  type: typeof SET_PRACTICE_MODIFICATION_AUDIO;
  payload: PracticeModificationModule | null | undefined;
}

export const setPracticeModificationAudio = (
  data: PracticeModificationModule | null | undefined,
): SetPracticeModificationAudioAction => ({
  type: SET_PRACTICE_MODIFICATION_AUDIO,
  payload: data,
});

interface SetPracticeModificationsAction {
  type: typeof SET_PRACTICE_MODIFICATIONS;
  payload: PracticeModificationModule[];
}

export const setPracticeModifications = (
  data: PracticeModificationModule[]
): SetPracticeModificationsAction => ({
  type: SET_PRACTICE_MODIFICATIONS,
  payload: data,
});

interface SetPracticeDisplayImagePublicImagesAction {
  type: typeof SET_PRACTICE_DISPLAY_IMAGE_PUBLIC_IMAGES;
  payload: string[];
}

export const setPracticeDisplayImagePublicImages = (
  data: string[]
): SetPracticeDisplayImagePublicImagesAction => ({
  type: SET_PRACTICE_DISPLAY_IMAGE_PUBLIC_IMAGES,
  payload: data,
});

interface SetMiniCourseModificationAction {
  type: typeof SET_MINI_COURSE_MODIFICATION;
  payload: MiniCourseModificationModule | null | undefined;
}

export const setMiniCourseModification = (
  data: MiniCourseModificationModule | null | undefined,
): SetMiniCourseModificationAction => ({
  type: SET_MINI_COURSE_MODIFICATION,
  payload: data,
});

interface SetMiniCourseModificationDisplayImageAction {
  type: typeof SET_MINI_COURSE_MODIFICATION_DISPLAY_IMAGE;
  payload: MiniCourseModificationModule | null | undefined;
}

export const setMiniCourseModificationDisplayImage = (
  data: MiniCourseModificationModule | null | undefined,
): SetMiniCourseModificationDisplayImageAction => ({
  type: SET_MINI_COURSE_MODIFICATION_DISPLAY_IMAGE,
  payload: data,
});

interface SetMiniCourseModificationsAction {
  type: typeof SET_MINI_COURSE_MODIFICATIONS;
  payload: MiniCourseModificationModule[];
}

export const setMiniCourseModifications = (
  data: MiniCourseModificationModule[]
): SetMiniCourseModificationsAction => ({
  type: SET_MINI_COURSE_MODIFICATIONS,
  payload: data,
});