import { Container, Stack, CircularProgress } from '@mui/material';

const AppLoader = () => {
    return (<Container component="main" maxWidth="xs">
    <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100%" }}>
        <CircularProgress color='secondary' />
    </Stack>
</Container>)
}

export default AppLoader;