import React from 'react';
import { ComponentType, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from "../hooks/app/useAppDispatch";
import { setAuthRoles } from '../redux/actions/authActions';

export interface WithRoleBasedRedirectOptions {
  roles: string[];
  superRoles?: string[];
}
const roleClaimType = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const withRoleBasedRedirect = <P extends object>(
  Component: ComponentType<P>,
  options: WithRoleBasedRedirectOptions
): React.FC<P> => (props: P): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getIdTokenClaims } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState(false);
  useEffect(() => {
    async function getRoles(): Promise<Array<string>> {
      const claims = await getIdTokenClaims();
      if(claims) {
        return claims[roleClaimType] || [];
      }
      return [];
    }
    async function checkRoles(roles: string[]) {
      const userRoles = await getRoles();
      dispatch(
        setAuthRoles(
          userRoles
        )
      );
      const isAuthorized = roles.every((role) => userRoles.includes(role));
      const isSuperRolesAuthorized = options.superRoles?.some((role) => userRoles.includes(role));
      if (!isAuthorized && !isSuperRolesAuthorized) {
        navigate('/');
      } else {
        setIsAuthorized(true);
      }
    }
    checkRoles(options.roles);
  }, [getIdTokenClaims, navigate]);
  return isAuthorized ? <Component {...props} /> : <></>;
};