import { SET_AUTH_TOKEN, SET_REFRESH_TOKEN, SET_ROLES, LOGOUT } from "../constants";

// authReducer.ts
interface AuthState {
  isLoggedIn: boolean;
  roles: string[];
  token: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  roles: [],
  token: null,
  refreshToken: null,
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload,
        isLoggedIn: true,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        refreshToken: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export default authReducer;
