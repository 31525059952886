import { Reducer } from 'redux';
import { SET_PREMIUM_USERS } from "../constants";
import { User } from "../../modules/User";

interface UserState  {
  premiumUsers: User[];
  }

const initialState: UserState = {
  premiumUsers: [],
  };
  
  const UserReducer: Reducer<UserState> = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_PREMIUM_USERS:
          return {
            ...state,
            premiumUsers: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default UserReducer;
  