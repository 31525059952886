// practiceActions.js
import axios from "axios";
import { SET_PREMIUM_USERS } from "../constants";
import { User } from "../../modules/User";

interface SetPremiumUsersAction {
  type: typeof SET_PREMIUM_USERS;
  payload: User[];
}

export const setPremiumUsers = (
  data: User[]
): SetPremiumUsersAction => ({
  type: SET_PREMIUM_USERS,
  payload: data,
});
