import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Auth0ProviderWithHistory from './Auth0Provider';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import Layout from './components/Layout';
import Profile from './components/Profile';
import AppTheme from './components/AppTheme';
import { ThemeProvider } from '@mui/material';
import Practices from './components/Practices';
import Practice from './components/Practice';
import MiniCourses from './components/MiniCourses';
import MiniCourse from './components/MiniCourse';
import Home from './components/Home';
import { HelmetProvider } from 'react-helmet-async';
import Practitioners from './components/Practitioners';
import AdminDashboard from './components/AdminDashboard';
import Teaser from './components/SomaStream/Teaser';
import SomaStreams from './components/SomaStream/SomaStreams';
import SomaStream from './components/SomaStream/SomaStream';
import ScheduledNotifications from './components/SomaStream/notifications/ScheduledNotifications';
import ScheduledNotification from './components/SomaStream/notifications/ScheduledNotification';

const adminRole = 'Admin';
const practitionerRole = 'Practitioner';
const PractitionerReviewerReadOnlyRole = 'PractitionerReviewerReadOnly';

function App() {
  return (
    <Router>
      <Auth0ProviderWithHistory>
        <HelmetProvider>
          <ThemeProvider theme={AppTheme}>
            <Layout>
              <Routes>

                <Route path="/" Component={Home} />

                <Route path='/profile/:id?' element={<AuthenticatedRoute component={Profile} />} />

                <Route path='/practitioners' element={<AuthenticatedRoute roles={[PractitionerReviewerReadOnlyRole]} superRoles={[adminRole]} component={Practitioners} />} />

                <Route path='/practices' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole, PractitionerReviewerReadOnlyRole]} component={Practices} />} />

                <Route path='/practice/:id?' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole, PractitionerReviewerReadOnlyRole]} component={Practice} />} />

                <Route path='/mini-courses' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole]} component={MiniCourses} />} />

                <Route path='/mini-course/:id?' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole]} component={MiniCourse} />} />
                
                <Route path='/admin-dashboard' element={<AuthenticatedRoute roles={[adminRole]} component={AdminDashboard} />} />
                
                <Route path='/soma-streams-coming-soon' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole]}  component={Teaser} />} />
                
                {/* <Route path='/soma-streams' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole]}  component={SomaStreams} />} />

                <Route path='/soma-stream/:id?' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole]} component={SomaStream} />} />
                
                <Route path='/soma-stream/:id/notifications' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole]} component={ScheduledNotifications} />} />
                
                <Route path='/soma-stream/:somaStreamId/notification/:id?' element={<AuthenticatedRoute roles={[practitionerRole]} superRoles={[adminRole]} component={ScheduledNotification} />} /> */}

              </Routes>
            </Layout>
          </ThemeProvider>
        </HelmetProvider>
      </Auth0ProviderWithHistory>
    </Router>
  );
}

export default App;