import { PracticeModificationModule } from "../../modules/PracticeModification";
import { Reducer } from "redux";
import {
  SET_MINI_COURSE_MODIFICATION,
  SET_MINI_COURSE_MODIFICATIONS,
  SET_MINI_COURSE_MODIFICATION_DISPLAY_IMAGE,
  SET_PRACTICE_DISPLAY_IMAGE_PUBLIC_IMAGES,
  SET_PRACTICE_MODIFICATION,
  SET_PRACTICE_MODIFICATIONS,
  SET_PRACTICE_MODIFICATION_AUDIO,
  SET_PRACTICE_MODIFICATION_DISPLAY_IMAGE,
} from "../constants";
import { MiniCourseModificationModule } from "../../modules/MiniCourseModification";

interface PracticeState {
  practiceModification: PracticeModificationModule | null;
  practiceModifications: PracticeModificationModule[];
  practiceDisplayImagePublicImages: string[];
  miniCourseModification: MiniCourseModificationModule | null;
  miniCourseModifications: MiniCourseModificationModule[];
}

const initialState: PracticeState = {
  practiceModification: null,
  practiceModifications: [],
  practiceDisplayImagePublicImages: [],
  miniCourseModification: null,
  miniCourseModifications: [],
};

const practiceReducer: Reducer<PracticeState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_PRACTICE_MODIFICATION:
      return {
        ...state,
        practiceModification: action.payload,
      };
    case SET_PRACTICE_MODIFICATION_DISPLAY_IMAGE:
      return {
        ...state,
        practiceModification: {
          ...state.practiceModification ?? {},
          id: action.payload?.id,
          displayImgUrl: action.payload?.displayImgUrl,
        },
      };
    case SET_PRACTICE_MODIFICATION_AUDIO:
      return {
        ...state,
        practiceModification: {
          ...state.practiceModification ?? {},
          id: action.payload?.id,
          audioUrl: action.payload?.audioUrl,
          durationInSeconds: action.payload?.durationInSeconds,
        },
      };
    case SET_PRACTICE_MODIFICATIONS:
      return {
        ...state,
        practiceModifications: action.payload,
      };
    case SET_PRACTICE_DISPLAY_IMAGE_PUBLIC_IMAGES:
      return {
        ...state,
        practiceDisplayImagePublicImages: action.payload,
      };
    case SET_MINI_COURSE_MODIFICATION:
      return {
        ...state,
        miniCourseModification: action.payload,
      };
    case SET_MINI_COURSE_MODIFICATION_DISPLAY_IMAGE:
      return {
        ...state,
        miniCourseModification: {
          ...state.miniCourseModification ?? {},
          id: action.payload?.id,
          displayImgUrl: action.payload?.displayImgUrl,
        },
      };
    case SET_MINI_COURSE_MODIFICATIONS:
      return {
        ...state,
        miniCourseModifications: action.payload,
      };
    default:
      return state;
  }
};

export default practiceReducer;
