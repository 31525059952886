import { combineReducers, AnyAction, Reducer } from 'redux';
import practitionerReducer from './practitionerReducer';
import authReducer from './authReducer';
import practiceReducer from './practitceReducer';
import CategoryReducer from './categoryReducer';
import UserReducer from './userReducer';
import SomaStreamReducer from './somaStreamReducer';

interface RootState {
    auth: ReturnType<typeof authReducer>;
    practitioner: ReturnType<typeof practitionerReducer>;
    practice: ReturnType<typeof practiceReducer>;
    category: ReturnType<typeof CategoryReducer>;
    user: ReturnType<typeof UserReducer>;
    somaStream: ReturnType<typeof SomaStreamReducer>;
  }

const rootReducer: Reducer<RootState, AnyAction> = combineReducers({
  auth: authReducer,
  practitioner: practitionerReducer,
  practice: practiceReducer,
  category: CategoryReducer,
  user: UserReducer,
  somaStream: SomaStreamReducer,
});

export default rootReducer;