import { setCategories } from "../../redux/actions/categoryActions";
import { useAppDispatch } from "../app/useAppDispatch";
import useApi from "./useApi";

const useCategoryApi = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  return {

    fetchCategories: async () => {
      try {
        const response = await api.get(
          "Category/GetCategories"
        );
        const categories = response.data;
        dispatch(setCategories(categories));
        return categories;
      } catch (error) {
        console.error("Error fetching categories data:", error);
      }
    },
    
  };
};

export default useCategoryApi;
