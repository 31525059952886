import { PracticeModificationModule } from "./PracticeModification";
import { PractitionerModule } from "./Practitioner";

export interface PractitionerModificationModule {
    id: number,
    created?: Date,
    existingPractitionerId?: number,
    existingPractitioner?: PractitionerModule,
    status: PractitionerModificationStatus
    firstName?: string,
    lastName?: string,
    fullName?: string,
    bio?: string,
    displayImgUrl?: string,
    pronouns?: string,
    title?: string,
    links?: string[],
    practiceModifications?: PracticeModificationModule[],
}

export enum PractitionerModificationStatus {
    INITIAL = 0,
    DRAFT = 1,
    SUBMITTED = 2,
    IN_REVIEW = 3,
    ACCEPTED = 4,
    REJECTED = 5
}