import { Reducer } from "redux";
import { SET_SOMA_STREAM, SET_SOMA_STREAMS, SET_SOMA_STREAMS_SCHEDULED_NOTIFICATION, SET_SOMA_STREAMS_SCHEDULED_NOTIFICATIONS, SET_SOMA_STREAM_DISPLAY_IMAGE } from "../constants";
import { SomaStreamModule } from "../../modules/SomaStream";
import { SomaStreamScheduledNotificationModule } from "../../modules/SomaStreamScheduledNotification";

interface SomaStreamState {
  somaStreams: SomaStreamModule[];
  somaStream: SomaStreamModule | null;
  somaStreamScheduledNotifications: SomaStreamScheduledNotificationModule[];
  somaStreamScheduledNotification: SomaStreamScheduledNotificationModule | null;
}

const initialState: SomaStreamState = {
  somaStreams: [],
  somaStream: null,
  somaStreamScheduledNotifications: [],
  somaStreamScheduledNotification: null,
};

const SomaStreamReducer: Reducer<SomaStreamState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_SOMA_STREAMS:
      return {
        ...state,
        somaStreams: action.payload,
      };
    case SET_SOMA_STREAM:
      return {
        ...state,
        somaStream: action.payload,
      };
    case SET_SOMA_STREAM_DISPLAY_IMAGE:
      return {
        ...state,
        somaStream: {
          ...(state.somaStream ?? {}),
          id: action.payload?.id,
          displayImgUrl: action.payload?.displayImgUrl,
        },
      };
    case SET_SOMA_STREAMS_SCHEDULED_NOTIFICATIONS:
      return {
        ...state,
        somaStreamScheduledNotifications: action.payload,
      };
    case SET_SOMA_STREAMS_SCHEDULED_NOTIFICATION:
      return {
        ...state,
        somaStreamScheduledNotification: action.payload,
      };
    default:
      return state;
  }
};

export default SomaStreamReducer;
