import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SignOutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import Sidebar from './SideBar';
import { setAuthRoles, setAuthToken } from '../redux/actions/authActions';
import { useAppDispatch } from '../hooks/app/useAppDispatch';

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paddingNotMobile: {
    paddingLeft: drawerWidth,
    marginLeft: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  signOutButton: {
    marginLeft: theme.spacing(2),
  },
}));

interface LayoutProps {
  children: any
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { user } = useAuth0();
  const { isAuthenticated, logout } = useAuth0();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mobileOpen, setMobileOpen] = useState(false);

  const isMobile = screenWidth >= 600;

  const dispatch = useAppDispatch();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // if user info is changed, update the roles
  useEffect(() => {
    if (user) {
      dispatch(
        setAuthRoles(
          user[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ]
        )
      );
    }
  }, [user])

  const signOut = () => {
    logout({ logoutParams: { returnTo: window.location.origin } }).then(() => {
      dispatch(setAuthRoles([]));
      dispatch(setAuthToken(null));
    });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={`${classes.appBar} ${isMobile && classes.paddingNotMobile}`}>
        <Toolbar>
          {!isMobile && (<IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setMobileOpen(!mobileOpen)}
          >
            <MenuIcon />
          </IconButton>)}
          <Typography variant="h6" noWrap>
            SomaShare Practitioner Dashboard
          </Typography>
          <div style={{ flexGrow: 1, textAlign: 'right' }}>
            {isAuthenticated && (
              <IconButton
                edge="start"
                className={classes.signOutButton}
                color="inherit"
                aria-label="sign-out"
                onClick={signOut}
              >
                <SignOutIcon />
              </IconButton>)}
          </div>
        </Toolbar>
      </AppBar>
      <Sidebar screenWidth={screenWidth} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <main className={`${classes.content} ${isMobile && classes.paddingNotMobile}`}>
        <Toolbar />
        <Container maxWidth="lg">
          {/* Body content */}
          {children}
        </Container>
      </main>
    </div>
  );
};

export default Layout;