// api.ts
import axios, { AxiosInstance } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../app/useAppDispatch";
import { setAuthRoles } from "../../redux/actions/authActions";

export const useApi = (): AxiosInstance => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const dispatch = useAppDispatch();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL!,
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
    },
  });

  api.interceptors.request.use(
    async (config) => {
      try {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();

          if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;

            // set the roles
            if (user) {
              dispatch(
                setAuthRoles(
                  user[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                  ]
                )
              );
            }
          }
        }
        return config;
      } catch (error) {
        console.error('Failed to get access token:', error);
        return Promise.reject(error);
      }
    },
    (error) => {
      console.error('Request interceptor error:', error);
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('API network error:', error);
      return Promise.reject(error);
    }
  );

  return api;
};

export default useApi;
