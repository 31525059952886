import { PractitionerModificationModule } from "../../modules/PractitionerModification";
import { Reducer } from "redux";
import {
  SET_PRACTITIONERS,
  SET_PRACTITIONER_MODIFICATION,
  SET_PRACTITIONER_MODIFICATIONS,
  SET_PRACTITIONER_MODIFICATION_DISPLAY_IMAGE,
} from "../constants";
import { PractitionerModule } from "../../modules/Practitioner";

interface PractitionerState {
  practitioners: PractitionerModule[];
  practitionerModifications: PractitionerModificationModule[];
  practitionerModification: PractitionerModificationModule | null;
}

const initialState: PractitionerState = {
  practitioners: [],
  practitionerModifications: [],
  practitionerModification: null,
};

const practitionerReducer: Reducer<PractitionerState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_PRACTITIONERS:
      return {
        ...state,
        practitioners: action.payload,
      };
    case SET_PRACTITIONER_MODIFICATIONS:
      return {
        ...state,
        practitionerModifications: action.payload,
      };
    case SET_PRACTITIONER_MODIFICATION:
      return {
        ...state,
        practitionerModification: action.payload,
      };
    case SET_PRACTITIONER_MODIFICATION_DISPLAY_IMAGE:
      return {
        ...state,
        practitionerModification: {
          ...state.practitionerModification ?? {},
          id: action.payload?.id,
          displayImgUrl: action.payload?.displayImgUrl,
        },
      };
    default:
      return state;
  }
};

export default practitionerReducer;
