import { MiniCourseModificationModule } from "./MiniCourseModification";
import { PracticeModule } from "./Practice";
import { PractitionerModule } from "./Practitioner";

export interface PracticeModificationModule {
    id: number,
    existingPracticeId?: number,
    existingPractice?: PracticeModule,
    status: PracticeModificationStatus
    title?: string,
    description?: string,
    displayImgUrl?: string,
    audioUrl?: string,
    durationInSeconds?: number,
    categories?: number[],
    practitioner?: PractitionerModule,
    miniCourseModification?: MiniCourseModificationModule,
    // virtual property for the multiselect component
    selectedCategories?: any[]
    // virtual property to make sure user agree to the terms
    agreedToTerms?: boolean,
}

export enum PracticeModificationStatus {
    DRAFT = 0,
    SUBMITTED = 1,
    IN_REVIEW = 2,
    ACCEPTED = 3,
    REJECTED = 4
}