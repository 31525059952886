import { RootState } from "../store";

export const practiceModificationSelector = (state: RootState) =>
  state?.practice?.practiceModification;

export const practiceModificationsSelector = (state: RootState) =>
  state?.practice?.practiceModifications;

export const practiceDisplayImagePublicImagesSelector = (state: RootState) =>
  state?.practice?.practiceDisplayImagePublicImages;

export const miniCourseModificationSelector = (state: RootState) =>
  state?.practice?.miniCourseModification;

export const miniCourseModificationsSelector = (state: RootState) =>
  state?.practice?.miniCourseModifications;