// WelcomeMessage.tsx

import React, { useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import SEO from './SEO';

const useStyles = makeStyles((theme: any) => ({
  container: {
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const Home: React.FC = () => {
  const classes = useStyles();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <div className={classes.container}>
      <SEO 
        description='Manage your SomaShare Practitioner practices and content'
      />
      {!isAuthenticated && (
        <Box alignItems='center' justifyContent='center' display="flex">
          <Box textAlign='center' margin={2} border={2} padding={2} sx={{ borderColor: 'secondary.main' }}>
            <Button
              type="button"
              onClick={() => loginWithRedirect()}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Sign In
            </Button>
            &nbsp;or&nbsp;
            <Button
              type="button"
              onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Sign Up
            </Button>
            <Typography variant="h6" marginTop={1}>
              To manage your account
            </Typography>
          </Box>
        </Box>)}
      <Typography variant="h4" gutterBottom>
        Welcome, SomaShare Practitioners!
      </Typography>
      <Typography variant="body1" paragraph>
        We are so excited that you’re interested in applying to share your somatic practices on the app.
      </Typography>
      <Typography variant="body1" paragraph>
        This is an incredible opportunity to expose your work to thousands of potential clients, to impact and touch so many people who need you, and to participate in many more money-making and impact-driven opportunities through the SomaShare platform.
      </Typography>
      <Typography variant="body1" paragraph>
        Here are more details about this collaboration:
      </Typography>
      <Typography variant="h5" gutterBottom>
        About SomaShare
      </Typography>
      <Typography variant="body1" paragraph>
        SomaShare is an app that brings practical, easy-to-follow tools and a communal space to support people in deep healing and transformation.
      </Typography>
      <Typography variant="body1" paragraph>
        We created SomaShare to encourage a shift from black and white thinking into inclusive, nuanced and emotionally mature ways of being through practices, like nervous system regulation, emotional healing tools and more, to create a resilient life.
      </Typography>
      <Typography variant="body1" paragraph>
        We thoughtfully select the best practitioners; our goal is to create a trauma-informed platform that will never push individuals too far out of their comfort zone, or shame them for their pace of transformation. Instead, practices will challenge participants just enough to create powerful, integrated shifts in their ways of being.
      </Typography>
      <Typography variant="h5" gutterBottom>
        General Guidelines:
      </Typography>
      <ul>
        <li>All your submissions are <b>trauma informed</b>. This means never pushing through pain and discomfort (rather, honoring it), reminding listeners to stay in their window of tolerance, and to always listen to their body - their wisdom precedes our wisdom as practitioners. If something doesn’t feel good/right, perhaps it is not the day to do it, or perhaps it doesn’t work for them, and that’s ok.</li>
        <li>Practices are <b>actionable</b> (i.e. something that can be repeated and practiced to change your state in some way) and <b>simple to implement</b> (i.e. provide simple, clear instructions on how to implement the practice you are offering). </li>
        <li>Practices are for <b>implementation during the audio guidance</b> rather than after they listen (you may include some things to continue reflecting on, but the emphasis is on transformation during the practice of the audio).</li>
      </ul>
      <Typography variant="h5" gutterBottom>
        Practitioner Guidelines
      </Typography>
      <ul>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          The Specifics
        </Typography>
        <li>You may submit a practice, multiple individual practices, or a mini course.</li>
        <li>During the submission process, you will be provided with a list of categories to choose from for your practices/mini courses. You may also submit a suggested category with a brief description to us for approval before creating your practices by sending it to <a href='mailto:info@somashare.com' target='_blank'>info@somashare.com</a></li>
        <li>Make sure your audio is of great quality, clear and with no background noise. </li>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Practice
        </Typography>
        <li>Each practice should be a minimum of 3 minutes and up to 33 minutes.</li>
        <li>Please provide a short simple title (up to 50 characters), description (up to 2000 characters) and up to 6 categories.</li>
        <li>We can choose to make some of the practices available only to our premium members community.</li>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Mini Course
        </Typography>
        <li>3 or more practices (up to 15) on the same topic that follow a process.</li>
        <li>Please provide a mini course title (up to 50 characters) and description (up to 1000 characters) in addition to individual practice titles and descriptions and let us know the order of the practices. Please also include the appropriate categories (up to 6) in the form for the mini course as a whole, and for each individual practice if needed</li>
        <li>First video of the mini course can be an introductory audio explaining the mini course, rather than a practice.</li>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Practices that we don’t share in our app:
        </Typography>
        <li>Practices that invalidate a listener’s current sensations, emotions, or state (for example: “your fear is not real, focus on your happiness”)</li>
        <li>Practices that don’t provide enough guidance to the listener when needed (for example: “feel your emotion” - and does not explain how to actually do this)</li>
        <li>Informational audios, instead of guided practices, or informational audios that give instructions for things to do after you listen rather than ones that guide you on a process while listening </li>
      </ul>
      <Typography variant="h5" gutterBottom>
        Recording Tips & Guidelines
      </Typography>
      <ul>
        <li>Make sure you are in a very quiet space, undisturbed and with no background noise</li>
        <li>It is best to use a professional microphone such as The Blue Yeti (or any other microphone you typically use in recording audio practices) - make sure you research the best settings for your particular microphone to assure the best quality of sound</li>
        <li>Avoid unnecessary/unintentional silence moments (for example - cut out any silences at the start/end of the recording); a free editing tool we like is <a href='https://podcastle.ai' target='_blank'>podcastle</a>  </li>
        <li>Free audio recording platforms with tutorials: <a href='https://www.youtube.com/watch?v=AXSyOyFQ2VE' target='_blank'>audacity</a>, <a href='https://podcastle.ai/' target='_blank'>podcastle</a>, <a href='https://support.apple.com/guide/voice-memos/record-vmaa4b813415/mac' target='_blank'>Voice Memos</a> and Garage Band on mac </li>
        <li>Free music: if you’d like to add music, <a href='https://www.bensound.com/free-music-for-videos' target='_blank'>here</a> is one source, but of course there are many if you just search for royalty free music. Be sure to add the attribution in your form</li>
        <li>Make sure that if you use music, it <b>won’t be copyrighted</b> - use only royalty free music available for the public domain </li>
        <li>Audio file format must be <b>MP3</b> <a href='https://cloudconvert.com/' target='_blank'>here</a> is s a free tool to convert to MP3 </li>
      </ul>
      {!isAuthenticated && (<Typography variant="body1" paragraph>
        If you already have an account, please sign in. Otherwise, sign up to get started!
      </Typography>)}
      {!isAuthenticated && (
        <Box alignItems='center' justifyContent='center' display="flex">
          <Box textAlign='center' margin={2} border={2} padding={2} sx={{ borderColor: 'secondary.main' }}>
            <Button
              type="button"
              onClick={() => loginWithRedirect()}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Sign In
            </Button>
            &nbsp;or&nbsp;
            <Button
              type="button"
              onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Sign Up
            </Button>
            <Typography variant="h6" marginTop={1}>
              To manage your account
            </Typography>
          </Box>
        </Box>)}
      <Typography variant="h5" gutterBottom>
        Next Steps
      </Typography>
      <p>
        After you complete and submit your profile and it is approved by us, you can start working on your practices and mini courses. Once you submit them, they will be either accepted or rejected. If they are rejected, you'll be notified about the reason, and be able to re-submit them.
      </p>
      <p>
        With so much love and respect for you and your work!
      </p>
      <p>
        The SomaShare Team
      </p>
    </div>
  );
};

export default Home;
