// WelcomeMessage.tsx

import React, { useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SEO from '../SEO';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
    container: {
    },
}));

const Teaser: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SEO
                title="SomaStream"
                description='Coming Soon: SomaStream, a practitioner live feature'
            />
            <Typography variant="h3" gutterBottom>
                SomaStream
            </Typography>
            {/* <Link to='/soma-streams'>SomaStreams</Link> */}
            <Typography variant="h6" paragraph margin={4}>
                We are ecstatic to announce that SomaStream, our upcoming feature that allows you to share live webinars right inside of the app, is currently in development. Get ready to magnify your impact (and income!) through this amazing feature - exclusive to SomaShare practitioners and an amazing way to connect with a larger audience and create powerful paid webinars.
            </Typography>
        </div>
    );
};

export default Teaser;
