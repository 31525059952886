import { MiniCourseModule } from "./MiniCourse";

export interface MiniCourseModificationModule {
    id: number,
    existingMiniCourseId?: number,
    existingMiniCourse?: MiniCourseModule,
    status: MiniCourseModificationStatus
    title?: string,
    description?: string,
    displayImgUrl?: string,
    categories?: number[],
    // virtual property for the multiselect component
    selectedCategories?: any[],
    practices?: number[],
    // virtual property for the multiselect component
    selectedPractices?: any[]
    // virtual property to make sure user agree to the terms
    agreedToTerms?: boolean,
}

export enum MiniCourseModificationStatus {
    DRAFT = 0,
    SUBMITTED = 1,
    IN_REVIEW = 2,
    ACCEPTED = 3,
    REJECTED = 4
}