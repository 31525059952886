import { setPremiumUsers } from "../../redux/actions/userActions";
import { useAppDispatch } from "../app/useAppDispatch";
import useApi from "./useApi";

const useUserApi = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  return {

    fetchPremiumUsers: async () => {
      try {
        const response = await api.get(
          "User/GetPremiumUsers"
        );
        const users = response.data;
        dispatch(setPremiumUsers(users));
        return users;
      } catch (error) {
        console.error("Error fetching premium users data:", error);
      }
    },
    
  };
};

export default useUserApi;
