import React from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    Divider,
    ListItemIcon,
} from '@mui/material';
import ProfileIcon from '@mui/icons-material/Person';
import PractitionersIcon from '@mui/icons-material/Diversity1';
import PracticesIcon from '@mui/icons-material/AudioFile';
import MiniCoursesIcon from '@mui/icons-material/PlaylistPlay';
import SomaStreamsIcon from '@mui/icons-material/Stream';
import AdminDashboardIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { isAdminSelector, isPractitionerReviewerReadOnlySelector, isPractitionerSelector } from '../redux/selectors/authSelector';

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
}));

interface ResponsiveSidebarProps {
    screenWidth: number,
    mobileOpen: boolean,
    setMobileOpen: Function,
}

const ResponsiveSidebar: React.FC<any> = ({ screenWidth, mobileOpen, setMobileOpen }: ResponsiveSidebarProps) => {
    const classes = useStyles();

    const isPractitioner = useAppSelector(isPractitionerSelector);
    const isAdmin = useAppSelector(isAdminSelector);
    const isPractitionerReviewerReadOnly = useAppSelector(isPractitionerReviewerReadOnlySelector);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Drawer
            variant={screenWidth >= 600 ? 'permanent' : 'temporary'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem button component={Link} to='/' onClick={() => setMobileOpen(false)}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                {(isAdmin || isPractitionerReviewerReadOnly) && (
                    <>
                        {isAdmin && (<ListItem button component={Link} to='admin-dashboard' onClick={() => setMobileOpen(false)}>
                            <ListItemIcon>
                                <AdminDashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Admin Dashboard" />
                        </ListItem>)}
                        <ListItem button component={Link} to='practitioners' onClick={() => setMobileOpen(false)}>
                            <ListItemIcon>
                                <PractitionersIcon />
                            </ListItemIcon>
                            <ListItemText primary="Practitioners" />
                        </ListItem>
                        <ListItem button component={Link} to='practices' onClick={() => setMobileOpen(false)}>
                            <ListItemIcon>
                                <PracticesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Practices" />
                        </ListItem>
                    </>
                )}
                {(!isAdmin && !isPractitionerReviewerReadOnly) && (<ListItem button component={Link} to='profile' onClick={() => setMobileOpen(false)}>
                    <ListItemIcon>
                        <ProfileIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                </ListItem>)}
                {(isPractitioner || isAdmin) && (
                    <>
                    <ListItem button component={Link} to='practices' onClick={() => setMobileOpen(false)}>
                            <ListItemIcon>
                                <PracticesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Practices" />
                        </ListItem>
                        <ListItem button component={Link} to='mini-courses' onClick={() => setMobileOpen(false)}>
                            <ListItemIcon>
                                <MiniCoursesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Mini Courses" />
                        </ListItem>
                        <ListItem button component={Link} to='soma-streams-coming-soon' onClick={() => setMobileOpen(false)}>
                            <ListItemIcon>
                                <SomaStreamsIcon />
                            </ListItemIcon>
                            <ListItemText primary="SomaStreams" />
                        </ListItem>
                    </>)}
            </List>
        </Drawer>
    );
};

export default ResponsiveSidebar;
