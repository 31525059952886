import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid, TablePagination, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { miniCourseModificationsSelector } from '../redux/selectors/practiceSelector';
import { MiniCourseModificationModule, MiniCourseModificationStatus } from '../modules/MiniCourseModification';
import usePracticeApi from '../hooks/api/usePracticeApi';
import { truncateString } from '../utils/stringUtils';
import ImageIcon from '@mui/icons-material/Image';
import AppLoader from './AppLoader';
import SEO from './SEO';
import { isAdminSelector } from '../redux/selectors/authSelector';

const MiniCourses: React.FC = () => {

    const { fetchMiniCourseModifications } = usePracticeApi();

    const [isLoading, setIsLoading] = useState(false);

    const isAdmin = useAppSelector(isAdminSelector);

    const miniCourseModifications = useAppSelector(miniCourseModificationsSelector);
    useEffect(() => {
        const fetchData = async () => {
            console.log('fetching data..');
            setIsLoading(true);
            await fetchMiniCourseModifications().then((data: MiniCourseModificationModule[]) => {
                setIsLoading(false);
            })
        }
        fetchData();
    }, []);

    // search
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState<MiniCourseModificationStatus | 'All'>(isAdmin ? MiniCourseModificationStatus.SUBMITTED : 'All');
    const filteredMiniCourses = miniCourseModifications.filter((miniCourseModification: MiniCourseModificationModule) =>
        (!searchQuery || miniCourseModification?.title?.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (statusFilter === 'All' || (miniCourseModification?.status === statusFilter))
    );

    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const paginatedMiniCourses = filteredMiniCourses.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    if (isLoading) {
        return (<AppLoader />);
    }

    return (
        <div>
            <SEO
                title='Mini Courses'
                description='View all your mini courses status'
            />
            <h1>Mini Courses</h1>
            <div style={{ textAlign: 'right' }}>
                <Link to={!isAdmin ? "/mini-course" : "#"}>
                    <Button variant="contained" color="primary" disabled={isAdmin}>
                        Create a new mini course
                    </Button>
                </Link>
            </div>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <TextField
                        margin='normal'
                        label="Search by Title"
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => { setPage(0); setSearchQuery(e.target.value) }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl style={{ minWidth: '100%', marginTop: '16px' }}>
                        <InputLabel id="status-filter-label">Status</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="status-filter-label"
                            label="Status"
                            id="status-filter"
                            value={statusFilter}
                            onChange={(e) => { setPage(0); setStatusFilter(e.target.value as MiniCourseModificationStatus | 'All') }}
                        >
                            <MenuItem key="All" value="All">All</MenuItem>
                            {Object.keys(MiniCourseModificationStatus).filter(p => isNaN(Number(p))).map((statusKey: any) => (
                                <MenuItem key={statusKey} value={MiniCourseModificationStatus[statusKey]}>
                                    {statusKey?.replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <List>
                {paginatedMiniCourses?.map((miniCourseModification: MiniCourseModificationModule, index: number) => (
                    <ListItem key={miniCourseModification.id + '_' + index} button component={Link} to={`/mini-course/${miniCourseModification.id}`}>
                        <Grid container spacing={0}>
                            <Grid item md={1}>
                                <ListItemAvatar>
                                    <Avatar alt={miniCourseModification.title} variant='square'
                                        src={miniCourseModification?.displayImgUrl ?? miniCourseModification?.existingMiniCourse?.displayImgUrl}>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                            </Grid>
                            <Grid item md={2}>
                                <ListItemText primary={MiniCourseModificationStatus[miniCourseModification?.status]?.replaceAll('_', ' ')} />
                                <>{miniCourseModification?.practices?.length} practices</>
                            </Grid>
                            <Grid item md={2}>
                                <ListItemText primary={miniCourseModification?.existingMiniCourseId && miniCourseModification.existingMiniCourseId > 0 ? 'PUBLISHED' : 'UNPUBLISHED'} />
                            </Grid>
                            <Grid item md={7}>
                                <ListItemText primary={miniCourseModification.title} secondary={truncateString(miniCourseModification.description, 300)} />
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
            <TablePagination
                component="div"
                variant='footer'
                count={filteredMiniCourses.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default MiniCourses;