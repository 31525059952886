import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Button,
    Container,
    Grid,
    Typography,
    Box,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    Fade,
    Alert,
    AlertTitle,
} from '@mui/material';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { practiceModificationSelector, practiceDisplayImagePublicImagesSelector } from '../redux/selectors/practiceSelector';
import usePracticeApi from '../hooks/api/usePracticeApi';
import { PracticeModificationModule, PracticeModificationStatus } from '../modules/PracticeModification';
import CropImage from './CropImage/CropImage';
import { useAppDispatch } from '../hooks/app/useAppDispatch';
import { setPracticeModification } from '../redux/actions/practiceActions';
import { categoriesSelector } from '../redux/selectors/categorySelector';
import useCategoryApi from '../hooks/api/useCategoryApi';
import { CategoryModule } from '../modules/Category';
import ReactAudioPlayer from 'react-audio-player';
import AppLoader from './AppLoader';
import { isAdminSelector, isPractitionerReviewerReadOnlySelector } from '../redux/selectors/authSelector';
import SEO from './SEO';

const maxNumOfCategories = 6;
const validationSchema = Yup.object().shape({
    title: Yup.string().max(50).min(5),
    description: Yup.string().max(2000).min(20),
    selectedCategories: Yup.array().of(
        Yup.object()
    )
        .max(maxNumOfCategories),
    agreedToTerms: Yup.bool().oneOf([true], 'You must agree to all 3 agreements'),
});

const Practice: React.FC = () => {
    const { id } = useParams();
    const idNumber = !id ? 0 : Number(id);
    const navigate = useNavigate();
    const practiceModification = useAppSelector(practiceModificationSelector);
    const practiceDisplayImagePublicImages = useAppSelector(practiceDisplayImagePublicImagesSelector);
    const { fetchPracticeModification, savePracticeModification, updatePracticeModificationDisplayImage, updatePracticeModificationDisplayImageFromGallery,
        updatePracticeModificationAudioUrl, fetchPracticeModificationDisplayImage, fetchPracticeModificationAudio,
        reviewPracticeModification, approvePracticeModification, rejectPracticeModification,
        fetchPracticeDisplayImagePublicImages, fetchPracticeModificationPreSignUrl } = usePracticeApi();
    const [initialValues, setInitialValues] = useState<SavePracticeModificationProps>({ ...{ submitType: 'save', selectedCategories: [], agreedToTerms: false }, ...practiceModification } as SavePracticeModificationProps);
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState<SavePracticeModificationProps>(initialValues);
    const isSubmitValid: boolean = Boolean(values && values?.title && values.description &&
        (practiceModification?.displayImgUrl || practiceModification?.existingPractice?.displayImgUrl) &&
        (practiceModification?.audioUrl || practiceModification?.existingPractice?.audioUrl) && practiceModification?.durationInSeconds &&
        values.categories && values.categories.length > 0);
    const dispatch = useAppDispatch();
    const [isUploadingAudio, setIsUploadingAudio] = useState(false);
    const categories = useAppSelector(categoriesSelector);
    const { fetchCategories } = useCategoryApi();
    const [audioUrl, setAudioUrl] = useState('');
    const isAdmin = useAppSelector(isAdminSelector);
    const isPractitionerReviewerReadOnly = useAppSelector(isPractitionerReviewerReadOnlySelector);
    const idDisabledRole = isAdmin || isPractitionerReviewerReadOnly;
    const [saveAlertVisibility, setSaveAlertVisibility] = useState<boolean>(false);
    const [isPremium, setIsPremium] = useState<boolean>(practiceModification?.existingPractice?.isPremium ?? false);
    const [isActive, setIsActive] = useState<boolean>(!(practiceModification?.existingPractice?.id) ? true : practiceModification?.existingPractice?.isActive);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const[fetchDisplayImageOnly, setFetchDisplayImageOnly] = useState(false);
    const[fetchAudioUrlOnly, setFetchAudioUrlOnly] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setFetchDisplayImageOnly(false);
            setFetchAudioUrlOnly(false);
            if (!(categories?.length > 0)) {
                await fetchCategories().then(async (data) => {
                    await getPracticeModification(data);
                })
            } else {
                await getPracticeModification(categories);
            }
            if (!(practiceDisplayImagePublicImages?.length > 0)) {
                await fetchPracticeDisplayImagePublicImages();
            }
        }
        fetchData();
        return () => {
            dispatch(setPracticeModification(null));
        }
    }, [id]);

    const getPracticeModification = async (allCategories: CategoryModule[]) => {
        if (idNumber > 0) {
            console.log('fetching data..');
            setIsLoading(true);
            if(fetchDisplayImageOnly) {
                await fetchPracticeModificationDisplayImage(idNumber).then((data) => {
                    if(data) {
                        prepareFields({ ...values,  id: data?.id ?? 0, displayImgUrl: data?.displayImgUrl }, allCategories);
                    }
                    setIsLoading(false);
                });
            } else if(fetchAudioUrlOnly) {
                fetchPracticeModificationAudio(idNumber).then((data) => {
                    if(data) {
                        prepareFields({ ...values,  id: data?.id ?? 0, audioUrl: data?.audioUrl, durationInSeconds: data?.durationInSeconds}, allCategories);
                    }
                    setIsLoading(false);
                });
            } else {
                await fetchPracticeModification(idNumber).then((data) => {
                    if (data) {
                        prepareFields(data, allCategories);
                    }
                    setIsLoading(false);
                })
            }
        }
    }

    const redirectAfterInsert = (createdId: number) => {
        if (!(idNumber > 0)) {
            navigate(`/practice/${createdId}`);
        }
        return;
    }

    const handleSave = async (values: PracticeModificationModule) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));
        delete valuesCopy.selectedCategories;
        delete valuesCopy.agreedToTerms;
        await savePracticeModification(valuesCopy).then((id: number) => {
            if (id > 0) {
                redirectAfterInsert(id);
            }
        });
    }

    const prepareFields = (practiceModification: PracticeModificationModule | null, allCategories: CategoryModule[]) => {
        setInitialValues({
            submitType: 'save',
            agreedToTerms: false,
            id: practiceModification?.id || 0,
            existingPracticeId: practiceModification?.existingPracticeId,
            existingPractice: practiceModification?.existingPractice,
            status: practiceModification?.status || PracticeModificationStatus.DRAFT,
            title: practiceModification?.title || '',
            description: practiceModification?.description || '',
            categories: practiceModification?.categories || [],
            selectedCategories: allCategories.filter((category) => practiceModification?.categories?.includes(category.id)),
        });
    };

    const handleSubmit = async (values: SavePracticeModificationProps, { setSubmitting, setFieldValue }: any) => {
        try {
            console.log('saving data..');
            setSaveAlertVisibility(true);
            if (values?.submitType === 'save') {
                values.status = PracticeModificationStatus.DRAFT;
                await handleSave(values);
            } else if (values?.submitType === 'submit') {
                values.status = PracticeModificationStatus.SUBMITTED;
                await handleSave(values);
            }
            await fetchPracticeModification(idNumber);
        } catch (error) {
            console.error('Error saving as draft', error);
        } finally {
            setSubmitting(false);
            setFieldValue('submitType', 'save');
            setSaveAlertVisibility(false);
        }
    };

    const handleUploadImageSubmit = async (practiceModificationId: number, file: File | null) => {
        let fetchedId: number = 0;
        try {
            if (!file) {
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            await updatePracticeModificationDisplayImage(practiceModificationId, formData).then((id: number) => {
                fetchedId = id;
            });
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            if (fetchedId > 0) {
                setFetchDisplayImageOnly(true);
                redirectAfterInsert(fetchedId);
            } else {
                await fetchPracticeModificationDisplayImage(idNumber);
            }
            // setSubmitting(false);
            // setFieldValue('submitType', 'save');
        }
    };

    const handleImageFromGallerySelected = async (practiceModificationId: number, url: string) => {
        await updatePracticeModificationDisplayImageFromGallery(practiceModificationId, url).then((id: number) => {
            if (id > 0) {
                setFetchDisplayImageOnly(true);
                redirectAfterInsert(id);
            }
        });
        await fetchPracticeModificationDisplayImage(idNumber);
    }

    const handleUploadAudioSubmit = async (practiceModificationId: number, file: File | null) => {
        setIsUploadingAudio(true);
        let isCompleted = false;
        let id = 0;
        try {
            if (!file) {
                return;
            }

            const chunkSize = 5 * 1024 * 1024; // 5 MB
            const totalChunks = Math.ceil(file.size / chunkSize);
            let uploadId = "";
            let prevETags = "";
            let totalDuration: number = 0;
            for (let i = 0; i < totalChunks; i++) {
                const start = i * chunkSize;
                const end = Math.min((i + 1) * chunkSize, file.size);
                const partNumber = i + 1;
                const isLastPart = i === totalChunks - 1;

                const chunk = file.slice(start, end);

                const formData = new FormData();
                formData.append('filePart', chunk);
                formData.append('fileName', file?.name);
                formData.append('practiceModificationId', practiceModificationId?.toString());
                formData.append('partNumber', partNumber?.toString());
                formData.append('isLastPart', isLastPart?.toString());
                formData.append('uploadId', uploadId);
                formData.append('prevETags', prevETags);
                formData.append('totalDuration', totalDuration?.toString());
                let isError = false;
                await updatePracticeModificationAudioUrl(formData).then((result: any) => {
                    if (!result) {
                        isError = true;
                    } else {
                        if(result.id > 0) {
                            practiceModificationId = result.id;
                        }
                        uploadId = result.uploadId;
                        prevETags = result.prevETags;
                        if (!isNaN(result.totalDuration)) {
                            totalDuration = Number(result.totalDuration);
                        }
                        if (result.isCompleted && result.id > 0) {
                            isCompleted = true;
                            id = result.id
                        }
                    }
                })
                if (isError) {
                    console.log('error! cancelling upload..');
                    break;
                }
            }
            await fetchPracticeModificationAudio(idNumber);
        } catch (error) {
            console.error('Error uploading audio:', error);
        } finally {
            setIsUploadingAudio(false);
            setAudioUrl('');
            resetFileInput();
            // setSubmitting(false);
            // setFieldValue('submitType', 'save');
            if (isCompleted && id > 0) {
                setFetchAudioUrlOnly(true);
                redirectAfterInsert(id);
            }
        }
    };

    const resetFileInput = () => {
        if(inputFileRef?.current) {
            inputFileRef.current.value = '';
        }
    }

    if (!(categories?.length > 0) || (id && !practiceModification?.id) || isLoading) {
        return (
            <AppLoader />
        );
    }

    return (
        <Container component="main" maxWidth="sm">
            <SEO
                title='Practice'
                description='Create or Edit your practice'
            />
            <div>
                <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                        <Typography component="h1" variant="h5">
                            Practice
                        </Typography>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <div style={{ marginBottom: '20px', float: 'right' }}>
                            <Link to="/practices">
                                <Button variant="contained" color="secondary">
                                    &larr; All practices
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
                {idNumber > 0 && (<Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ marginBottom: '20px' }}>
                            <Typography component="h1" variant="h6">
                                Status: {practiceModification && PracticeModificationStatus[practiceModification?.status]?.replaceAll('_', ' ')}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>)}
                <Formik
                    innerRef={(formikActions) => (formikActions ? setValues(formikActions.values) : setValues(initialValues))}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validateOnMount
                    validateOnBlur
                    validateOnChange
                >
                    {({ isSubmitting, setFieldValue, isValid, values, touched, errors, dirty }: any) => (
                        <Form>
                            <input type="hidden" value="save" name="submitType" />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        disabled={idDisabledRole}
                                        as={TextField}
                                        variant="outlined"
                                        label="Title"
                                        name="title"
                                        fullWidth
                                        InputLabelProps={{ shrink: values.title }}
                                        helperText={<ErrorMessage name="title" />}
                                        error={Boolean(touched.title && errors.title)}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        disabled={idDisabledRole}
                                        as={TextField}
                                        variant="outlined"
                                        label="Description"
                                        name="description"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        InputLabelProps={{ shrink: values.description }}
                                        helperText={<ErrorMessage name="description" />}
                                        error={Boolean(touched.description && errors.description)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CropImage
                                        imageButtonPreviewVariant='square'
                                        cropAspect={400 / 333}
                                        minHeight={Math.ceil(600)}
                                        disabled={idDisabledRole || practiceModification?.status === PracticeModificationStatus.IN_REVIEW || isUploadingAudio}
                                        uploadButtonText={`${practiceModification?.displayImgUrl || practiceModification?.existingPractice?.displayImgUrl ? 'Modify' : 'Upload'} Practice Image`}
                                        handleUploadImage={async (f) => await handleUploadImageSubmit(idNumber, f)} existingImage={practiceModification?.displayImgUrl ?? practiceModification?.existingPractice?.displayImgUrl}
                                        galleryImageUrls={practiceDisplayImagePublicImages}
                                        handleSelectImageFromGalleryImage={async (img) => await handleImageFromGallerySelected(idNumber, img)}
                                    />
                                </Grid>
                                <Grid item xs={12}
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center">
                                    <Field name="audioUrl">
                                        {({ field, form }: any) => (
                                            <>
                                                <Button variant="contained" component="label" color="primary" disabled={idDisabledRole || practiceModification?.status === PracticeModificationStatus.IN_REVIEW || isUploadingAudio}>
                                                    {isUploadingAudio && <><AppLoader />&nbsp;&nbsp;Uploading...</>}
                                                    {!isUploadingAudio && (<>{practiceModification?.audioUrl || practiceModification?.existingPractice?.audioUrl ? 'Modify' : 'Upload'} Audio (MP3) File</>)}
                                                    <input hidden id="audioUrl" ref={inputFileRef} disabled={isUploadingAudio} type="file" accept=".mp3" onChange={async (e: any) => await handleUploadAudioSubmit(idNumber, e?.target?.files[0])} />
                                                </Button>
                                                {isUploadingAudio && <><br /><Typography component="label">Please do not close  the browser until the upload is complete</Typography></>}
                                            </>
                                        )}
                                    </Field>
                                </Grid>
                                {(practiceModification?.durationInSeconds && practiceModification.durationInSeconds > 0) ? (
                                    <Grid
                                        item xs={12}
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center">
                                        <Typography component='span'>Duration: {new Date(practiceModification?.durationInSeconds * 1000).toISOString().slice(11, 19)?.replace(/^(00\:)/, "")}</Typography>
                                        {audioUrl && (<ReactAudioPlayer
                                            src={audioUrl}
                                            autoPlay={false}
                                            controls
                                            controlsList={"nodownload"}
                                        />)}
                                        {!audioUrl && idNumber > 0 && (<Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disabled={isPractitionerReviewerReadOnly}
                                            onClick={async () => await fetchPracticeModificationPreSignUrl(idNumber).then((url) => { setAudioUrl(url) })}>Load Audio</Button>)}
                                    </Grid>) : <></>}
                                <Grid item xs={12} marginBottom={2}>
                                    <Field name="selectedCategories">
                                        {({ field, form }: any) => (
                                            <Autocomplete
                                                {...field}
                                                disabled={idDisabledRole || isLoading || isSubmitting || isUploadingAudio}
                                                multiple
                                                id="categories"
                                                options={categories}
                                                getOptionLabel={(option: CategoryModule) => option?.name}
                                                getOptionDisabled={(options) => (values?.selectedCategories?.length >= maxNumOfCategories ? true : false)}
                                                filterSelectedOptions
                                                disableCloseOnSelect
                                                disableClearable
                                                onChange={(event, newValue: any) => {
                                                    setFieldValue(field.name, newValue);
                                                    setFieldValue('categories', newValue?.map((v: CategoryModule) => v.id));
                                                }}
                                                loading={isLoading || !(categories?.length > 0)}
                                                limitTags={maxNumOfCategories}
                                                isOptionEqualToValue={(option: any, value: any) => option?.id == value?.id}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={Boolean(touched.selectedCategories && errors.selectedCategories)}
                                                        helperText={touched.selectedCategories && errors.selectedCategories}
                                                        label="Categories"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field type="checkbox" name="agreedToTerms">
                                        {({ field }: any) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        color="primary"
                                                        disabled={idDisabledRole}
                                                    />
                                                }
                                                label={
                                                    <>I agree to the <a href='https://www.somashare.com/practitioners-agreement' target='_blank'>Practitioner's Agreement</a>, <a href='https://www.somashare.com/terms-of-use' target='_blank'>Terms of Use</a> and <a href='https://www.somashare.com/privacy-policy' target='_blank'>Privacy Policy</a></>
                                                }
                                            />
                                        )}
                                    </Field>
                                    {errors.agreedToTerms && touched.agreedToTerms && (
                                        <p style={{ marginTop: '0px', color: '#ff604f', fontSize: '0.75rem', fontWeight: 400 }}>{errors.agreedToTerms}</p>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} marginLeft={2} marginTop={2}>
                                    <Typography component={'label'}>Practice can be reviewed only when in 'Submitted' status</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {saveAlertVisibility && (<Fade
                                        in={saveAlertVisibility}
                                        timeout={{ enter: 1000, exit: 1000 }}
                                        addEndListener={() => {
                                            setTimeout(() => {
                                                setSaveAlertVisibility(false)
                                            }, 2000);
                                        }}
                                    >
                                        <Alert style={{ position: 'fixed', top: 90, right: 10 }} severity="success" variant="standard" onClose={() => { setSaveAlertVisibility(false) }}>
                                            <AlertTitle>{values?.submitType == 'submit' ? 'Submitted' : 'Draft Saved'}</AlertTitle>
                                            Successfully saved changes
                                        </Alert>
                                    </Fade>)}
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={idDisabledRole || isSubmitting || isLoading || practiceModification?.status === PracticeModificationStatus.IN_REVIEW || isUploadingAudio}
                                    >
                                        Save as draft
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        disabled={idDisabledRole || isSubmitting || isLoading || !isValid || !isSubmitValid || practiceModification?.status === PracticeModificationStatus.IN_REVIEW
                                            || isUploadingAudio}
                                        onClick={() => setFieldValue('submitType', 'submit')}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                            {idDisabledRole && (
                                <Box margin={5}>
                                    <Grid container spacing={2} marginBottom={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                disabled={practiceModification?.status !== PracticeModificationStatus.SUBMITTED}
                                                onClick={() => reviewPracticeModification({ practiceModificationId: practiceModification?.id }).then(async () => {
                                                    await fetchPracticeModification(idNumber);
                                                })}
                                            >
                                                Start Reviewing
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                disabled={practiceModification?.status !== PracticeModificationStatus.IN_REVIEW}
                                                onClick={() => approvePracticeModification({ practiceModificationId: practiceModification?.id, isPremium: isPremium ?? false, isActive: isActive ?? false }).then(async () => {
                                                    await fetchPracticeModification(idNumber);
                                                })}
                                            >
                                                Approve
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={practiceModification?.status !== PracticeModificationStatus.IN_REVIEW}
                                                onClick={() => rejectPracticeModification({ practiceModificationId: practiceModification?.id }).then(async () => {
                                                    await fetchPracticeModification(idNumber);
                                                })}
                                            >
                                                Reject
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value={isPremium}
                                                        defaultChecked={practiceModification?.existingPractice?.isPremium}
                                                        onChange={(e: any, checked: boolean) => setIsPremium(Boolean(checked))}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <>Premium?</>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value={isActive}
                                                        defaultChecked={practiceModification?.existingPractice?.isActive}
                                                        onChange={(e: any, checked: boolean) => setIsActive(Boolean(checked))}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <>Active?</>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    );
};

export default Practice;

interface SavePracticeModificationProps extends PracticeModificationModule {
    submitType: 'save' | 'submit' | null
}