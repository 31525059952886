// Auth
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_ROLES = 'SET_ROLES';
export const LOGOUT = 'LOGOUT';

// Practitioner
export const SET_PRACTITIONERS = 'SET_PRACTITIONERS';
export const SET_PRACTITIONER_MODIFICATION = 'SET_PRACTITIONER_MODIFICATION';
export const SET_PRACTITIONER_MODIFICATION_DISPLAY_IMAGE = 'SET_PRACTITIONER_MODIFICATION_DISPLAY_IMAGE';
export const SET_PRACTITIONER_MODIFICATIONS = 'SET_PRACTITIONER_MODIFICATIONS';

// Practice
export const SET_PRACTICE_MODIFICATION = 'SET_PRACTICE_MODIFICATION';
export const SET_PRACTICE_MODIFICATION_DISPLAY_IMAGE = 'SET_PRACTICE_MODIFICATION_DISPLAY_IMAGE';
export const SET_PRACTICE_MODIFICATION_AUDIO = 'SET_PRACTICE_MODIFICATION_AUDIO';
export const SET_PRACTICE_MODIFICATIONS = 'SET_PRACTICE_MODIFICATIONS';
export const SET_PRACTICE_DISPLAY_IMAGE_PUBLIC_IMAGES = 'SET_PRACTICE_DISPLAY_IMAGE_PUBLIC_IMAGES';

// MiniCourse
export const SET_MINI_COURSE_MODIFICATION = 'SET_MINI_COURSE_MODIFICATION';
export const SET_MINI_COURSE_MODIFICATION_DISPLAY_IMAGE = 'SET_MINI_COURSE_MODIFICATION_DISPLAY_IMAGE';
export const SET_MINI_COURSE_MODIFICATIONS = 'SET_MINI_COURSE_MODIFICATIONS';

// Category
export const SET_CATEGORIES = 'SET_CATEGORIES';

// User
export const SET_PREMIUM_USERS = 'SET_PREMIUM_USERS';

// SomaStream
export const SET_SOMA_STREAMS = 'SET_SOMA_STREAMS';
export const SET_SOMA_STREAM = 'SET_SOMA_STREAM';
export const SET_SOMA_STREAM_DISPLAY_IMAGE = 'SET_SOMA_STREAM_DISPLAY_IMAGE';
export const SET_SOMA_STREAMS_SCHEDULED_NOTIFICATIONS = 'SET_SOMA_STREAMS_SCHEDULED_NOTIFICATIONS';
export const SET_SOMA_STREAMS_SCHEDULED_NOTIFICATION = 'SET_SOMA_STREAMS_SCHEDULED_NOTIFICATION';