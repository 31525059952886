import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid, TablePagination, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { practitionerModificationsSelector } from '../redux/selectors/practitionerSelector';
import { PractitionerModificationModule, PractitionerModificationStatus } from '../modules/PractitionerModification';
import usePractitionerApi from '../hooks/api/usePractitionerApi';
import { truncateString } from '../utils/stringUtils';
import ImageIcon from '@mui/icons-material/Image';
import AppLoader from './AppLoader';
import SEO from './SEO';
import { isAdminSelector } from '../redux/selectors/authSelector';
import moment from 'moment';

const Practitioners: React.FC = () => {

    const { fetchPractitionerModifications } = usePractitionerApi();

    const [isLoading, setIsLoading] = useState(false);

    const isAdmin = useAppSelector(isAdminSelector);

    const practitionerModifications = useAppSelector(practitionerModificationsSelector);
    useEffect(() => {
        const fetchData = async () => {
            console.log('fetching data..');
            setIsLoading(true);
            await fetchPractitionerModifications().then((data: PractitionerModificationModule[]) => {
                setIsLoading(false);
            })
        }
        fetchData();
    }, []);

    // search
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState<PractitionerModificationStatus | 'All'>(isAdmin ? PractitionerModificationStatus.SUBMITTED : 'All');
    const [submittedPracticesFilter, setSubmittedPracticesFilter] = useState<boolean | 'All'>('All');
    const filteredPractitioners = practitionerModifications.filter((practitionerModification: PractitionerModificationModule) =>
        (!searchQuery || (practitionerModification?.firstName?.toLowerCase() + ' ' + practitionerModification?.lastName?.toLowerCase()).includes(searchQuery.toLowerCase())) &&
        (statusFilter === 'All' || (practitionerModification?.status === statusFilter)) &&
        (submittedPracticesFilter === 'All' || 
            (submittedPracticesFilter?.toString() === "true" && practitionerModification?.practiceModifications?.length && practitionerModification?.practiceModifications?.length > 0) || 
            (submittedPracticesFilter?.toString() === "false" && !(practitionerModification?.practiceModifications?.length && practitionerModification?.practiceModifications?.length > 0)))
    );

    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const paginatedPractitioners = filteredPractitioners.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    if (isLoading) {
        return (
            <AppLoader />
        );
    }


    return (
        <div>
            <SEO
                title='Practitioners'
                description='View all your practitioners status'
            />
            <h1>Practitioners</h1>
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <TextField
                        margin='normal'
                        label="Search by Name"
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => { setPage(0); setSearchQuery(e.target.value) }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl style={{ minWidth: '100%', marginTop: '16px' }}>
                        <InputLabel id="status-filter-label">Status</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="status-filter-label"
                            label="Status"
                            id="status-filter"
                            value={statusFilter}
                            onChange={(e) => { setPage(0); setStatusFilter(e.target.value as PractitionerModificationStatus | 'All') }}
                        >
                            <MenuItem key="All" value="All">All</MenuItem>
                            {Object.keys(PractitionerModificationStatus).filter(p => isNaN(Number(p))).map((statusKey: any) => (
                                <MenuItem key={statusKey} value={PractitionerModificationStatus[statusKey]}>
                                    {statusKey?.replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl style={{ minWidth: '100%', marginTop: '16px' }}>
                        <InputLabel id="submitted-practices-filter-label">Submitted Practices</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="submitted-practices-filter-label"
                            label="Submitted Practices"
                            id="submitted-practices-filter"
                            value={submittedPracticesFilter}
                            onChange={(e) => { setPage(0); setSubmittedPracticesFilter(e.target.value as boolean | 'All') }}
                        >
                            <MenuItem key="All" value="All">All</MenuItem>
                            <MenuItem key="true" value="true">YES</MenuItem>
                            <MenuItem key="false" value="false">NO</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <List>
                {paginatedPractitioners?.map((practitionerModification: PractitionerModificationModule) => (
                    <ListItem key={practitionerModification.id} button component={Link} to={`/profile/${practitionerModification.id}`}>
                        <Grid container spacing={0}>
                            <Grid item md={1}>
                                <ListItemAvatar>
                                    <Avatar alt={practitionerModification.title} variant='circular'
                                        src={practitionerModification?.displayImgUrl ?? practitionerModification?.existingPractitioner?.displayImgUrl}>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                            </Grid>
                            <Grid item md={2}>
                                <ListItemText primary={PractitionerModificationStatus[practitionerModification?.status]?.replaceAll('_', ' ')} />
                            </Grid>
                            <Grid item md={2}>
                                <ListItemText primary={practitionerModification?.existingPractitionerId && practitionerModification.existingPractitionerId > 0 ? 'PUBLISHED' : 'UNPUBLISHED'} />
                            </Grid>
                            <Grid item md={5}>
                                <ListItemText primary={practitionerModification.fullName} secondary={truncateString(practitionerModification.bio, 300)} />
                            </Grid>
                            <Grid item md={2}>
                                <ListItemText primary={practitionerModification?.created ? moment.utc(practitionerModification.created).local().format('YYYY-MM-DD HH:mm:ss') : ''} />
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
            <TablePagination
                component="div"
                variant='footer'
                count={filteredPractitioners.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default Practitioners;