import { useEffect, useCallback, DependencyList } from 'react';

function useDebounceEffect(effect: any,  delay: number = 250, deps: DependencyList) {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

export default useDebounceEffect;
