import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray, FieldArrayRenderProps } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Select,
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
} from '@mui/material';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { practitionerModificationSelector } from '../redux/selectors/practitionerSelector';
import usePractitionerApi from '../hooks/api/usePractitionerApi';
import { PractitionerModificationModule, PractitionerModificationStatus } from '../modules/PractitionerModification';
import CropImage from './CropImage/CropImage';
import AppLoader from './AppLoader';
import { isAdminSelector, isPractitionerReviewerReadOnlySelector } from '../redux/selectors/authSelector';
import SEO from './SEO';
import { Link, useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(200).min(2),
  lastName: Yup.string().max(200).min(2),
  title: Yup.string().max(40).min(2),
  pronouns: Yup.string().max(20).min(2),
  bio: Yup.string().max(2000).min(50),
  links: Yup.array().of(
    Yup.string()
      .trim()
      .required('Link must be a valid URL')
      .url('Link must be a valid URL')
      .test('https', 'URL must start with https', (value) => {
        if (value) {
          return value.startsWith('https://');
        }
        return true; // If the field is empty, it's considered valid
      })
  )
    .max(3, 'You can add up to 3 Links'),
});

const Profile: React.FC = () => {
  const { id } = useParams();
  const idNumber = !id ? 0 : Number(id);

  const practitionerModification = useAppSelector(practitionerModificationSelector);
  const { fetchPractitionerModification, savePractitionerModification, updatePractitionerModificationAvatar,
    fetchPractitionerModificationDisplayImage,
    reviewPractitionerModification, approvePractitionerModification, rejectPractitionerModification } = usePractitionerApi();
  const [initialValues, setInitialValues] = useState<SavePractitionerModificationProps>({ ...{ submitType: 'save' }, ...practitionerModification } as SavePractitionerModificationProps);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<SavePractitionerModificationProps>(initialValues);
  const isSubmitValid = practitionerModification && practitionerModification?.firstName && practitionerModification.lastName && practitionerModification.title &&
    practitionerModification.pronouns && practitionerModification.bio && (practitionerModification.displayImgUrl || practitionerModification?.existingPractitioner?.displayImgUrl);
  const isAdmin = useAppSelector(isAdminSelector);
  const isPractitionerReviewerReadOnly = useAppSelector(isPractitionerReviewerReadOnlySelector);
  const shouldDisableBasedOnRole = isAdmin || isPractitionerReviewerReadOnly;

  useEffect(() => {
    const fetchData = async () => {
      console.log('fetching data..');
      setIsLoading(true);
      await fetchPractitionerModification(idNumber).then((data) => {
        if (data) {
          prepareFields(data);
        }
        setIsLoading(false);
      })
    }
    fetchData();
  }, []);


  const prepareFields = (practitionerModification: PractitionerModificationModule) => {
    setInitialValues({
      submitType: 'save',
      id: practitionerModification?.id || 0,
      existingPractitionerId: practitionerModification?.existingPractitionerId,
      existingPractitioner: practitionerModification?.existingPractitioner,
      status: practitionerModification?.status || PractitionerModificationStatus.DRAFT,
      firstName: practitionerModification?.firstName || '',
      lastName: practitionerModification?.lastName || '',
      fullName: practitionerModification?.fullName,
      title: practitionerModification?.title || '',
      pronouns: practitionerModification?.pronouns || '',
      bio: practitionerModification?.bio || '',
      links: practitionerModification?.links || [],
    });
  };

  const handleSubmit = async (values: SavePractitionerModificationProps, { setSubmitting, setFieldValue }: any) => {
    try {
      console.log('saving data..');
      if (values?.submitType === 'save') {
        values.status = PractitionerModificationStatus.DRAFT;
        await savePractitionerModification(values);
      } else if (values?.submitType === 'submit') {
        values.status = PractitionerModificationStatus.SUBMITTED;
        await savePractitionerModification(values);
      }
      await fetchPractitionerModification(idNumber);
    } catch (error) {
      console.error('Error saving as draft', error);
    } finally {
      setSubmitting(false);
      setFieldValue('submitType', 'save');
    }
  };

  const handleUploadImageSubmit = async (file: File | null) => {
    try {
      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      await updatePractitionerModificationAvatar(formData);
      await fetchPractitionerModificationDisplayImage(idNumber);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      // setSubmitting(false);
      // setFieldValue('submitType', 'save');
    }
  };

  const pronouns = ["She/Her", "He/Him", "They/Them", "They/She", "They/Him"];

  if (isLoading) {
    return (
      <AppLoader />
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <SEO
        title='Profile'
        description='Create or Edit your SomaShare Practitioner Profile'
      />
      <div>
        <Typography component="h1" variant="h5">
          Profile
        </Typography>
        {practitionerModification && (<Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ marginBottom: '20px' }}>
              <Typography component="h1" variant="h6">
                Status: {practitionerModification && PractitionerModificationStatus[practitionerModification?.status]?.replaceAll('_', ' ')}
              </Typography>
            </div>
          </Grid>
        </Grid>)}
        {(isAdmin || isPractitionerReviewerReadOnly) && (<Grid item sm={8} xs={12}>
          <div style={{ marginBottom: '20px', float: 'right' }}>
            <Link to="/practitioners">
              <Button variant="contained" color="secondary">
                &larr; All practitioners
              </Button>
            </Link>
          </div>
        </Grid>)}
        <Formik
          innerRef={(formikActions) => (formikActions ? setValues(formikActions.values) : setValues(initialValues))}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnMount
          validateOnBlur
          validateOnChange
        >
          {({ isSubmitting, setFieldValue, isValid, values, handleChange, handleBlur, touched, errors }: any) => (
            <Form>
              <input type="hidden" value="save" name="submitType" />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    disabled={shouldDisableBasedOnRole}
                    as={TextField}
                    variant="outlined"
                    label="First Name"
                    name="firstName"
                    fullWidth
                    InputLabelProps={{ shrink: values.firstName }}
                    helperText={<ErrorMessage name="firstName" />}
                    error={Boolean(touched.firstName && errors.firstName)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    disabled={shouldDisableBasedOnRole}
                    as={TextField}
                    variant="outlined"
                    label="Last Name"
                    name="lastName"
                    fullWidth
                    InputLabelProps={{ shrink: values.lastName }}
                    helperText={<ErrorMessage name="lastName" />}
                    error={Boolean(touched.lastName && errors.lastName)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    disabled={shouldDisableBasedOnRole}
                    as={TextField}
                    variant="outlined"
                    label="Your professional practitioner title"
                    name="title"
                    fullWidth
                    InputLabelProps={{ shrink: values.title }}
                    helperText={<ErrorMessage name="title" />}
                    error={Boolean(touched.title && errors.title)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="pronouns"
                    fullWidth
                    variant="outlined"
                  >
                    {({ field, form }: any) => (
                      <FormControl style={{ minWidth: '100%' }}>
                        <InputLabel id="pronouns-label">Pronouns</InputLabel>
                        <Select
                          disabled={shouldDisableBasedOnRole}
                          labelId="pronouns-label"
                          InputLabelProps={{ shrink: values.pronouns }}
                          label="Pronouns"
                          id="pronouns-select"
                          inputProps={{ name: 'pronouns' }}
                          {...field}
                          defaultValue={field.value}
                        >
                          {pronouns.map((pronoun) => (
                            <MenuItem key={pronoun} value={pronoun}>
                              {pronoun}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage name="pronouns" component={FormHelperText} />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    disabled={shouldDisableBasedOnRole}
                    as={TextField}
                    variant="outlined"
                    label="Bio"
                    name="bio"
                    multiline
                    rows={4}
                    fullWidth
                    InputLabelProps={{ shrink: values.bio }}
                    helperText={<ErrorMessage name="bio" />}
                    error={Boolean(touched.bio && errors.bio)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CropImage
                    disabled={shouldDisableBasedOnRole}
                    uploadButtonText={`${practitionerModification?.displayImgUrl || practitionerModification?.existingPractitioner?.displayImgUrl ? 'Modify' : 'Upload'} Profile Image`}
                    handleUploadImage={async (f) => await handleUploadImageSubmit(f)} existingImage={practitionerModification?.displayImgUrl ?? practitionerModification?.existingPractitioner?.displayImgUrl}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component={'label'}>You can add up to 3 personal links. For example: your website or social media</Typography>
                </Grid>
                <FieldArray name="links">
                  {(arrayHelpers: FieldArrayRenderProps) => (
                    <div style={{ marginBottom: '20px' }}>
                      {values.links?.map((link: string, index: number) => (
                        <Grid container spacing={2} key={index}>
                          <Grid item xs={10}>
                            <TextField
                              disabled={shouldDisableBasedOnRole}
                              fullWidth
                              margin='dense'
                              id={`links.${index}`}
                              name={`links.${index}`}
                              label="Link"
                              value={link}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.links?.[index] && Boolean(errors.links?.[index])}
                              helperText={touched.links?.[index] && errors.links?.[index]}
                            />
                          </Grid>
                          <Grid item xs={2} marginTop={1.5}>
                            <Button
                              disabled={shouldDisableBasedOnRole}
                              variant="contained"
                              color="secondary"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                      {(!values?.links?.length || values?.links?.length < 3) && (
                        <Button
                          disabled={shouldDisableBasedOnRole}
                          variant="contained"
                          color="primary"
                          onClick={() => arrayHelpers.push('')}
                        >
                          Add Link
                        </Button>
                      )}
                    </div>
                  )}
                </FieldArray>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || isLoading || shouldDisableBasedOnRole || practitionerModification?.status === PractitionerModificationStatus.IN_REVIEW}
                  >
                    Save as draft
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting || isLoading || !isValid || !isSubmitValid || shouldDisableBasedOnRole || practitionerModification?.status === PractitionerModificationStatus.IN_REVIEW}
                    onClick={() => setFieldValue('submitType', 'submit')}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              {isAdmin && (
                <Box margin={5}>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={practitionerModification?.status !== PractitionerModificationStatus.SUBMITTED}
                        onClick={() => reviewPractitionerModification({ practitionerModificationId: practitionerModification?.id }).then(async () => {
                          await fetchPractitionerModification(idNumber);
                        })}
                      >
                        Start Reviewing
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={practitionerModification?.status !== PractitionerModificationStatus.IN_REVIEW}
                        onClick={() => approvePractitionerModification({ practitionerModificationId: practitionerModification?.id }).then(async () => {
                          await fetchPractitionerModification(idNumber);
                        })}
                      >
                        Approve
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={practitionerModification?.status !== PractitionerModificationStatus.IN_REVIEW}
                        onClick={() => rejectPractitionerModification({ practitionerModificationId: practitionerModification?.id }).then(async () => {
                          await fetchPractitionerModification(idNumber);
                        })}
                      >
                        Reject
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default Profile;

interface SavePractitionerModificationProps extends PractitionerModificationModule {
  submitType: 'save' | 'submit' | null
}