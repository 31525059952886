// practitionerActions.js
import axios from "axios";
import { SET_PRACTITIONERS, SET_PRACTITIONER_MODIFICATION, SET_PRACTITIONER_MODIFICATIONS, SET_PRACTITIONER_MODIFICATION_DISPLAY_IMAGE } from "../constants";
import { PractitionerModificationModule } from "../../modules/PractitionerModification";
import { AppDispatch } from "../store";
import useApi from "../../hooks/api/useApi";
import { PractitionerModule } from "../../modules/Practitioner";

interface SetPractitionersAction {
  type: typeof SET_PRACTITIONERS;
  payload: PractitionerModule[];
}
export const setPractitioners = (
  data: PractitionerModule[]
): SetPractitionersAction => ({
  type: SET_PRACTITIONERS,
  payload: data,
});

interface SetPractitionerModificationAction {
  type: typeof SET_PRACTITIONER_MODIFICATION;
  payload: PractitionerModificationModule;
}

export const setPractitionerModification = (
  data: PractitionerModificationModule
): SetPractitionerModificationAction => ({
  type: SET_PRACTITIONER_MODIFICATION,
  payload: data,
});

interface SetPractitionerModificationDisplayImageAction {
  type: typeof SET_PRACTITIONER_MODIFICATION_DISPLAY_IMAGE;
  payload: PractitionerModificationModule | null | undefined;
}

export const setPractitionerModificationDisplayImage = (
  data: PractitionerModificationModule | null | undefined
): SetPractitionerModificationDisplayImageAction => ({
  type: SET_PRACTITIONER_MODIFICATION_DISPLAY_IMAGE,
  payload: data,
});


interface SetPractitionerModificationsAction {
  type: typeof SET_PRACTITIONER_MODIFICATIONS;
  payload: PractitionerModificationModule[];
}
export const setPractitionerModifications = (
  data: PractitionerModificationModule[]
): SetPractitionerModificationsAction => ({
  type: SET_PRACTITIONER_MODIFICATIONS,
  payload: data,
});
