import { MiniCourseModificationModule } from "../../modules/MiniCourseModification";
import { PracticeModificationModule } from "../../modules/PracticeModification";
import { setPracticeModification, setPracticeModifications, setPracticeDisplayImagePublicImages, setMiniCourseModification, setMiniCourseModifications, setPracticeModificationDisplayImage, setMiniCourseModificationDisplayImage, setPracticeModificationAudio } from "../../redux/actions/practiceActions";
import { useAppDispatch } from "../app/useAppDispatch";
import useApi from "./useApi";

const usePracticeApi = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  return {
    fetchPracticeModification: async (id: number) => {
      try {
        const response = await api.get(
          `Practice/GetPracticeModification/${id}`
        );
        const practiceModification = response.data;
        dispatch(setPracticeModification(practiceModification));
        return practiceModification;
      } catch (error) {
        console.error("Error fetching practice modification data:", error);
      }
    },

    fetchPracticeModificationDisplayImage: async (id?: number | undefined | null) => {
      try {
        const response = await api.get(
          `Practice/GetPracticeModification/${id}`
        );
        const practiceModification: PracticeModificationModule = response.data;
        dispatch(setPracticeModificationDisplayImage(practiceModification));
        return practiceModification;
      } catch (error) {
        console.error("Error fetching practice modification data:", error);
      }
    },

    fetchPracticeModificationAudio: async (id?: number | undefined | null) => {
      try {
        const response = await api.get(
          `Practice/GetPracticeModification/${id}`
        );
        const practiceModification: PracticeModificationModule = response.data;
        dispatch(setPracticeModificationAudio(practiceModification));
        return practiceModification;
      } catch (error) {
        console.error("Error fetching practice modification data:", error);
      }
    },


    fetchPracticeModifications: async () => {
      try {
        const response = await api.get(
          "Practice/GetPracticeModifications"
        );
        const practiceModifications = response.data;
        dispatch(setPracticeModifications(practiceModifications));
        return practiceModifications;
      } catch (error) {
        console.error("Error fetching practice modifications data:", error);
      }
    },
    

    savePracticeModification: async (practiceModificationData: PracticeModificationModule) => {
      try {
        const response = await api.post(
          "Practice/SavePracticeModification",
          practiceModificationData
        );
        return response?.data;
      } catch (error) {
        console.error("Error saving practice modification:", error);
      }
    },

    updatePracticeModificationDisplayImage: async (practiceModificationId: number, file: FormData) => {
      try {
        const response = await api.post(
          `Practice/updatePracticeModificationDisplayImage/${practiceModificationId}`,
          file
        );
        return response?.data;
      } catch (error) {
        console.error("Error updating practice modification avatar:", error);
      }
    },

    updatePracticeModificationDisplayImageFromGallery: async (practiceModificationId: number, url: string) => {
      try {
        const response = await api.post(
          `Practice/UpdatePracticeModificationDisplayImageFromGallery`,
          { practiceModificationId: practiceModificationId, url: encodeURIComponent(url) }
        );
        return response?.data;
      } catch (error) {
        console.error("Error updating practice modification avatar:", error);
      }
    },

    updatePracticeModificationAudioUrl: async (formData: FormData) => {
      try {
        const response = await api.post(
          `Practice/updatePracticeModificationAudioUrl`,
          formData,
        );
        return response?.data;
      } catch (error) {
        console.error("Error updating practice modification audio:", error);
      }
    },

    reviewPracticeModification: async (reviewPracticeModificationData: any) => {
      try {
        await api.post(
          "Practice/ReviewPracticeModification",
          reviewPracticeModificationData
        );
      } catch (error) {
        console.error("Error reviewing practice modification:", error);
      }
    },

    approvePracticeModification: async (approvePracticeModificationData: any) => {
      try {
        await api.post(
          "Practice/ApprovePracticeModification",
          approvePracticeModificationData
        );
      } catch (error) {
        console.error("Error approving practice modification:", error);
      }
    },

    rejectPracticeModification: async (rejectPracticeModificationData: any) => {
      try {
        await api.post(
          "Practice/RejectPracticeModification",
          rejectPracticeModificationData
        );
      } catch (error) {
        console.error("Error rejecting practice modification:", error);
      }
    },

    fetchPracticeDisplayImagePublicImages: async () => {
      try {
        const response = await api.get(
          "Practice/GetPracticeDisplayImagePublicImages"
        );
        const imagesUrls = response.data;
        dispatch(setPracticeDisplayImagePublicImages(imagesUrls));
        return imagesUrls;
      } catch (error) {
        console.error("Error fetching practice display image public images:", error);
      }
    },

    fetchMiniCourseModification: async (id: number) => {
      try {
        const response = await api.get(
          `Practice/GetMiniCourseModification/${id}`
        );
        const miniCourseModification = response.data;
        dispatch(setMiniCourseModification(miniCourseModification));
        return miniCourseModification;
      } catch (error) {
        console.error("Error fetching miniCourse modification data:", error);
      }
    },

    fetchMiniCourseModificationDisplayImage: async (id?: number | undefined | null) => {
      try {
        const response = await api.get(
          `Practice/GetMiniCourseModification/${id}`
        );
        const miniCourseModification = response.data;
        dispatch(setMiniCourseModificationDisplayImage(miniCourseModification));
        return miniCourseModification;
      } catch (error) {
        console.error("Error fetching mini course modification data:", error);
      }
    },

    fetchMiniCourseModifications: async () => {
      try {
        const response = await api.get(
          "Practice/GetMiniCourseModifications"
        );
        const miniCourseModifications = response.data;
        dispatch(setMiniCourseModifications(miniCourseModifications));
        return miniCourseModifications;
      } catch (error) {
        console.error("Error fetching miniCourse modifications data:", error);
      }
    },

    saveMiniCourseModification: async (miniCourseModificationData: MiniCourseModificationModule) => {
      try {
        const response = await api.post(
          "Practice/SaveMiniCourseModification",
          miniCourseModificationData
        );
        return response?.data;
      } catch (error) {
        console.error("Error saving miniCourse modification:", error);
      }
    },

    updateMiniCourseModificationDisplayImage: async (miniCourseModificationId: number, file: FormData) => {
      try {
        const response = await api.post(
          `Practice/updateMiniCourseModificationDisplayImage/${miniCourseModificationId}`,
          file
        );
        return response?.data;
      } catch (error) {
        console.error("Error updating miniCourse modification avatar:", error);
      }
    },

    updateMiniCourseModificationDisplayImageFromGallery: async (miniCourseModificationId: number, url: string) => {
      try {
        const response = await api.post(
          `Practice/UpdateMiniCourseModificationDisplayImageFromGallery`,
          { miniCourseModificationId: miniCourseModificationId, url: encodeURIComponent(url) }
        );
        return response?.data;
      } catch (error) {
        console.error("Error updating miniCourse modification avatar:", error);
      }
    },

    reviewMiniCourseModification: async (reviewMiniCourseModificationData: any) => {
      try {
        await api.post(
          "Practice/ReviewMiniCourseModification",
          reviewMiniCourseModificationData
        );
      } catch (error) {
        console.error("Error reviewing miniCourse modification:", error);
      }
    },

    approveMiniCourseModification: async (approveMiniCourseModificationData: any) => {
      try {
        await api.post(
          "Practice/ApproveMiniCourseModification",
          approveMiniCourseModificationData
        );
      } catch (error) {
        console.error("Error approving miniCourse modification:", error);
      }
    },

    rejectMiniCourseModification: async (rejectMiniCourseModificationData: any) => {
      try {
        await api.post(
          "Practice/RejectMiniCourseModification",
          rejectMiniCourseModificationData
        );
      } catch (error) {
        console.error("Error rejecting miniCourse modification:", error);
      }
    },

    fetchPracticeModificationPreSignUrl: async (practiceModificationId: number) => {
      try {
        const response = await api.get(
          `Practice/GetPracticeModificationPreSignUrl/${practiceModificationId}`
        );
        const preSignUrl = response.data;
        return preSignUrl;
      } catch (error) {
        console.error("Error fetching Practice Modification PreSign Url:", error);
      }
    },
  };
};

export default usePracticeApi;
