import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Select,
    Button,
    Container,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText,
    Box,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    Fade,
    Alert,
    AlertTitle,
} from '@mui/material';
import { useAppSelector } from '../hooks/app/useAppSelector';
import { miniCourseModificationSelector, practiceDisplayImagePublicImagesSelector, practiceModificationsSelector } from '../redux/selectors/practiceSelector';
import usePracticeApi from '../hooks/api/usePracticeApi';
import { MiniCourseModificationModule, MiniCourseModificationStatus } from '../modules/MiniCourseModification';
import CropImage from './CropImage/CropImage';
import { useAppDispatch } from '../hooks/app/useAppDispatch';
import { setMiniCourseModification } from '../redux/actions/practiceActions';
import { categoriesSelector } from '../redux/selectors/categorySelector';
import useCategoryApi from '../hooks/api/useCategoryApi';
import { CategoryModule } from '../modules/Category';
import { PracticeModificationModule } from '../modules/PracticeModification';
import AppLoader from './AppLoader';
import { isAdminSelector } from '../redux/selectors/authSelector';
import SEO from './SEO';

const maxNumOfCategories = 6;
const maxNumOfPractices = 12;
const validationSchema = Yup.object().shape({
    title: Yup.string().max(50).min(5),
    description: Yup.string().max(2000).min(20),
    selectedPractices: Yup.array().of(
        Yup.object()
    ),
    selectedCategories: Yup.array().of(
        Yup.object()
    )
        .max(maxNumOfCategories),
    agreedToTerms: Yup.bool().oneOf([true], 'You must agree to all 3 agreements'),
});

const MiniCourse: React.FC = () => {
    const { id } = useParams();
    const idNumber = !id ? 0 : Number(id);
    const navigate = useNavigate();
    const miniCourseModification = useAppSelector(miniCourseModificationSelector);
    const practiceDisplayImagePublicImages = useAppSelector(practiceDisplayImagePublicImagesSelector);
    const { fetchMiniCourseModification, fetchMiniCourseModificationDisplayImage, saveMiniCourseModification, updateMiniCourseModificationDisplayImage,
        reviewMiniCourseModification, approveMiniCourseModification, rejectMiniCourseModification,
        fetchPracticeModifications, fetchPracticeDisplayImagePublicImages, updateMiniCourseModificationDisplayImageFromGallery } = usePracticeApi();
    const [initialValues, setInitialValues] = useState<SaveMiniCourseModificationProps>({ ...{ submitType: 'save', selectedPractices: [], selectedCategories: [], agreedToTerms: false }, ...miniCourseModification } as SaveMiniCourseModificationProps);
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState<SaveMiniCourseModificationProps>(initialValues);
    const isSubmitValid: boolean = Boolean(values && values?.title && values.description &&
        (miniCourseModification?.displayImgUrl || miniCourseModification?.existingMiniCourse?.displayImgUrl) &&
        values.categories && values.categories.length > 0 &&
        values.practices && values.practices.length > 0);
    const canApprove = values?.selectedPractices && values.selectedPractices.length > 0 &&
        values.selectedPractices.filter((s: PracticeModificationModule) => s?.existingPracticeId && s.existingPracticeId > 0)?.length == values.selectedPractices.length;
    const dispatch = useAppDispatch();
    const categories = useAppSelector(categoriesSelector);
    const practices = useAppSelector(practiceModificationsSelector);
    const { fetchCategories } = useCategoryApi();
    const [showPracticedOrder, setShowPracticedOrder] = useState(false);
    const isAdmin = useAppSelector(isAdminSelector);
    const [saveAlertVisibility, setSaveAlertVisibility] = useState<boolean>(false);
    const[fetchDisplayImageOnly, setFetchDisplayImageOnly] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            setFetchDisplayImageOnly(false);
            await fetchPracticeModifications().then(async (practices) => {
                if (!(categories?.length > 0)) {
                    await fetchCategories().then(async (data) => {
                        await getMiniCourseModification(data, practices);
                    })
                } else {
                    await getMiniCourseModification(categories, practices);
                }
                if(!(practiceDisplayImagePublicImages?.length > 0)) {
                    await fetchPracticeDisplayImagePublicImages();
                }
            });
        }
        fetchData();
        return () => {
            dispatch(setMiniCourseModification(null));
        }
    }, [id]);

    const getMiniCourseModification = async (allCategories: CategoryModule[], practiceModifications: PracticeModificationModule[]) => {
        if (idNumber > 0) {
            console.log('fetching data..');
            setIsLoading(true);
            if (fetchDisplayImageOnly) {
                await fetchMiniCourseModificationDisplayImage(idNumber).then((data) => {
                    if(data) {
                        prepareFields({ ...values,  id: data?.id ?? 0, displayImgUrl: data?.displayImgUrl }, allCategories, practiceModifications);
                    }                        
                    setIsLoading(false);
                });
            } else {
                await fetchMiniCourseModification(idNumber).then((data) => {
                    if (data) {
                        prepareFields(data, allCategories, practiceModifications);
                    }
                    setIsLoading(false);
                })
            }
        }
    }

    const redirectAfterInsert = (createdId: number) => {
        if (!(idNumber > 0)) {
            navigate(`/mini-course/${createdId}`);
        }
        return;
    }

    const handleSave = async (values: MiniCourseModificationModule) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));
        delete valuesCopy.selectedCategories;
        delete valuesCopy.selectedPractices;
        delete valuesCopy.agreedToTerms;
        await saveMiniCourseModification(valuesCopy).then((id: number) => {
            if (id > 0) {
                redirectAfterInsert(id);
            }
        });
    }

    const prepareFields = (miniCourseModification: MiniCourseModificationModule | null, allCategories: CategoryModule[], practices: PracticeModificationModule[]) => {
        setInitialValues({
            submitType: 'save',
            agreedToTerms: false,
            id: miniCourseModification?.id || 0,
            existingMiniCourseId: miniCourseModification?.existingMiniCourseId,
            existingMiniCourse: miniCourseModification?.existingMiniCourse,
            status: miniCourseModification?.status || MiniCourseModificationStatus.DRAFT,
            title: miniCourseModification?.title || '',
            description: miniCourseModification?.description || '',
            categories: miniCourseModification?.categories || [],
            selectedCategories: allCategories.filter((category) => miniCourseModification?.categories?.includes(category.id)),
            practices: miniCourseModification?.practices || [],
            selectedPractices: getSortedSelectedPractices(miniCourseModification, practices),
        });
    };

    const getSortedSelectedPractices = (miniCourseModification: MiniCourseModificationModule | null, practices: PracticeModificationModule[]) => {
        let sortedPractices: PracticeModificationModule[] = [];
        miniCourseModification?.practices?.forEach((practiceId) => {
            const item = practices.find(p => p.id === practiceId);
            if (item) {
                sortedPractices.push(item);
            }
        });
        return sortedPractices;
    }

    const handleSubmit = async (values: SaveMiniCourseModificationProps, { setSubmitting, setFieldValue }: any) => {
        try {
            console.log('saving data..');
            setSaveAlertVisibility(true);
            if (values?.submitType === 'save') {
                values.status = MiniCourseModificationStatus.DRAFT;
                await handleSave(values);
            } else if (values?.submitType === 'submit') {
                values.status = MiniCourseModificationStatus.SUBMITTED;
                await handleSave(values);
            }
            await fetchMiniCourseModification(idNumber);
        } catch (error) {
            console.error('Error saving as draft', error);
        } finally {
            setSubmitting(false);
            setFieldValue('submitType', 'save');
            setSaveAlertVisibility(false);
        }
    };

    const handleUploadImageSubmit = async (miniCourseModificationId: number, file: File | null) => {
        let fetchedId: number = 0;
        
        try {
            if (!file) {
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            await updateMiniCourseModificationDisplayImage(miniCourseModificationId, formData).then((id: number) => {
                fetchedId = id;
            });
            await fetchMiniCourseModification(idNumber);
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            if (fetchedId > 0) {
                setFetchDisplayImageOnly(true);
                redirectAfterInsert(fetchedId);
            } else {
                await fetchMiniCourseModification(idNumber);
            }
            // setSubmitting(false);
            // setFieldValue('submitType', 'save');
        }
    };

    const handleImageFromGallerySelected = async (miniCourseModificationId: number, url: string) => {
        await updateMiniCourseModificationDisplayImageFromGallery(miniCourseModificationId, url).then((id: number) => {
            if (id > 0) {
                setFetchDisplayImageOnly(true);
                redirectAfterInsert(id);
            }
        });
        await fetchMiniCourseModificationDisplayImage(idNumber);
    }

    if (!(categories?.length > 0) || (id && !miniCourseModification?.id) || isLoading) {
        return (
            <AppLoader />
        );
    }

    const arrayMove = (arr: any[], oldIndex: number, newIndex: number) => {
        if (newIndex >= arr.length) {
            var k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr; // for testing
    };

    return (
        <Container component="main" maxWidth="sm">
            <SEO
                title='Mini Course'
                description='Create or Edit your mini course'
            />
            <div>
                <Grid container spacing={2}>
                    <Grid item sm={5} xs={12}>
                        <Typography component="h1" variant="h5">
                            Mini Course
                        </Typography>
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <div style={{ marginBottom: '20px', float: 'right' }}>
                            <Link to="/mini-courses">
                                <Button variant="contained" color="secondary">
                                    &larr; All mini courses
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
                {idNumber > 0 && (<Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ marginBottom: '20px' }}>
                            <Typography component="h1" variant="h6">
                                Status: {miniCourseModification && MiniCourseModificationStatus[miniCourseModification?.status]?.replaceAll('_', ' ')}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>)}
                <Formik
                    innerRef={(formikActions) => (formikActions ? setValues(formikActions.values) : setValues(initialValues))}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validateOnMount
                    validateOnBlur
                    validateOnChange
                >
                    {({ isSubmitting, setFieldValue, isValid, values, touched, errors }: any) => (
                        <Form>
                            <input type="hidden" value="save" name="submitType" />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        disabled={isAdmin}
                                        as={TextField}
                                        variant="outlined"
                                        label="Title"
                                        name="title"
                                        fullWidth
                                        InputLabelProps={{ shrink: values.title }}
                                        helperText={<ErrorMessage name="title" />}
                                        error={Boolean(touched.title && errors.title)}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        disabled={isAdmin}
                                        as={TextField}
                                        variant="outlined"
                                        label="Description"
                                        name="description"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        InputLabelProps={{ shrink: values.description }}
                                        helperText={<ErrorMessage name="description" />}
                                        error={Boolean(touched.description && errors.description)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CropImage
                                        imageButtonPreviewVariant='square'
                                        cropAspect={400 / 333}
                                        minHeight={Math.ceil(600)}
                                        disabled={isAdmin || miniCourseModification?.status === MiniCourseModificationStatus.IN_REVIEW}
                                        uploadButtonText={`${miniCourseModification?.displayImgUrl || miniCourseModification?.existingMiniCourse?.displayImgUrl ? 'Modify' : 'Upload'} Mini Course Image`}
                                        handleUploadImage={async (f) => await handleUploadImageSubmit(idNumber, f)} existingImage={miniCourseModification?.displayImgUrl ?? miniCourseModification?.existingMiniCourse?.displayImgUrl}
                                        galleryImageUrls={practiceDisplayImagePublicImages}
                                        handleSelectImageFromGalleryImage={async (img) => await handleImageFromGallerySelected(idNumber, img)}
                                    />
                                </Grid>
                                <Grid item xs={12} marginBottom={2}>
                                    <Field name="selectedCategories">
                                        {({ field, form }: any) => (
                                            <Autocomplete
                                                disabled={isAdmin}
                                                {...field}
                                                multiple
                                                id="categories"
                                                options={categories}
                                                getOptionLabel={(option: CategoryModule) => option?.name}
                                                getOptionDisabled={(options) => (values?.selectedCategories?.length >= maxNumOfCategories ? true : false)}
                                                filterSelectedOptions
                                                disableCloseOnSelect
                                                disableClearable
                                                onChange={(event, newValue: any) => {
                                                    setFieldValue(field.name, newValue);
                                                    setFieldValue('categories', newValue?.map((v: CategoryModule) => v.id));
                                                }}
                                                loading={isLoading || !(categories?.length > 0)}
                                                limitTags={maxNumOfCategories}
                                                isOptionEqualToValue={(option: any, value: any) => option?.id == value?.id}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={Boolean(touched.selectedCategories && errors.selectedCategories)}
                                                        helperText={touched.selectedCategories && errors.selectedCategories}
                                                        label="Categories"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} marginLeft={2} marginTop={-1} marginBottom={-1}>
                                    <Typography component={'label'}>3 or more practices (up to 15). After creating the <Link target="_blank" to='/practices'>practices</Link>, select them here</Typography>
                                </Grid>
                                <Grid item xs={12} marginBottom={2}>
                                    <Field name="selectedPractices">
                                        {({ field, form }: any) => (
                                            <Autocomplete
                                                {...field}
                                                disabled={isAdmin}
                                                multiple
                                                id="practices"
                                                options={practices}
                                                getOptionLabel={(option: PracticeModificationModule) => option?.title}
                                                getOptionDisabled={(options) => (values?.selectedPractices?.length >= maxNumOfPractices ? true : false)}
                                                filterSelectedOptions
                                                disableCloseOnSelect
                                                disableClearable
                                                onChange={(event, newValue: any) => {
                                                    setFieldValue(field.name, newValue);
                                                    setFieldValue('practices', newValue?.map((v: PracticeModificationModule) => v.id));
                                                }}
                                                loading={isLoading || !(practices?.length > 0)}
                                                limitTags={maxNumOfPractices}
                                                isOptionEqualToValue={(option: any, value: any) => option?.id == value?.id}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={Boolean(touched.selectedPractices && errors.selectedPractices)}
                                                        helperText={touched.selectedPractices && errors.selectedPractices}
                                                        label="Practices"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                {values?.practices?.length > 1 && (<Grid item xs={12}>
                                    {!showPracticedOrder ? (
                                        <Button type="button"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setShowPracticedOrder(!showPracticedOrder)}
                                        >
                                            Manage Practices Order
                                        </Button>
                                    ) : (
                                        <>
                                            <Typography component="h5" variant="h5">
                                                Manage Practices Order
                                            </Typography>
                                            {values?.selectedPractices?.map((selectedPractice: PracticeModificationModule, index: number) => (
                                                <>
                                                    Position:&nbsp;&nbsp;
                                                    <Select value={index}
                                                        disabled={isAdmin}
                                                        onChange={(e) => {
                                                            const newArr = arrayMove(values.selectedPractices, index, Number(e.target.value));
                                                            setFieldValue('selectedPractices', newArr);
                                                            setFieldValue('practices', newArr?.map((v: PracticeModificationModule) => v.id));
                                                        }}
                                                    >
                                                        {values?.selectedPractices.map((p: any, index: number) => (
                                                            <MenuItem key={index} value={index}>
                                                                {index + 1}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    &nbsp; Title: &nbsp;{selectedPractice?.title}
                                                    <br />
                                                </>
                                            ))}
                                        </>
                                    )}
                                </Grid>)}
                                <Grid item xs={12}>
                                    <Field type="checkbox" name="agreedToTerms">
                                        {({ field }: any) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        disabled={isAdmin}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <>I agree to the <a href='https://www.somashare.com/practitioners-agreement' target='_blank'>Practitioner's Agreement</a>, <a href='https://www.somashare.com/terms-of-use' target='_blank'>Terms of Use</a> and <a href='https://www.somashare.com/privacy-policy' target='_blank'>Privacy Policy</a></>
                                                }
                                            />
                                        )}
                                    </Field>
                                    {errors.agreedToTerms && touched.agreedToTerms && (
                                        <p style={{ marginTop: '0px', color: '#ff604f', fontSize: '0.75rem', fontWeight: 400 }}>{errors.agreedToTerms}</p>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} marginLeft={2} marginTop={2}>
                                    <Typography component={'label'}>Mini Course can be reviewed only when in 'Submitted' status</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {saveAlertVisibility && (<Fade
                                        in={saveAlertVisibility}
                                        timeout={{ enter: 1000, exit: 1000 }}
                                        addEndListener={() => {
                                            setTimeout(() => {
                                                setSaveAlertVisibility(false)
                                            }, 2000);
                                        }}
                                    >
                                        <Alert style={{ position: 'fixed', top: 90, right: 10 }} severity="success" variant="standard" onClose={() => { setSaveAlertVisibility(false) }}>
                                            <AlertTitle>{values?.submitType == 'submit' ? 'Submitted' : 'Draft Saved'}</AlertTitle>
                                            Successfully saved changes
                                        </Alert>
                                    </Fade>)}
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isAdmin || isSubmitting || isLoading || miniCourseModification?.status === MiniCourseModificationStatus.IN_REVIEW}
                                    >
                                        Save as draft
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        disabled={isAdmin || isSubmitting || isLoading || !isValid || !isSubmitValid || miniCourseModification?.status === MiniCourseModificationStatus.IN_REVIEW}
                                        onClick={() => setFieldValue('submitType', 'submit')}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                            {isAdmin && (
                                <Box margin={5}>
                                    <Grid container spacing={2} marginBottom={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                disabled={miniCourseModification?.status !== MiniCourseModificationStatus.SUBMITTED || !canApprove}
                                                onClick={() => reviewMiniCourseModification({ miniCourseModificationId: miniCourseModification?.id }).then(async () => {
                                                    await fetchMiniCourseModification(idNumber);
                                                })}
                                            >
                                                Start Reviewing
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                disabled={miniCourseModification?.status !== MiniCourseModificationStatus.IN_REVIEW || !canApprove}
                                                onClick={() => approveMiniCourseModification({ miniCourseModificationId: miniCourseModification?.id }).then(async () => {
                                                    await fetchMiniCourseModification(idNumber);
                                                })}
                                            >
                                                Approve
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={miniCourseModification?.status !== MiniCourseModificationStatus.IN_REVIEW}
                                                onClick={() => rejectMiniCourseModification({ miniCourseModificationId: miniCourseModification?.id }).then(async () => {
                                                    await fetchMiniCourseModification(idNumber);
                                                })}
                                            >
                                                Reject
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    );
};

export default MiniCourse;

interface SaveMiniCourseModificationProps extends MiniCourseModificationModule {
    submitType: 'save' | 'submit' | null
}