// practiceActions.js
import axios from "axios";
import { SET_CATEGORIES } from "../constants";
import { CategoryModule } from "../../modules/Category";

interface SetCategoriesAction {
  type: typeof SET_CATEGORIES;
  payload: CategoryModule[];
}

export const setCategories = (
  data: CategoryModule[]
): SetCategoriesAction => ({
  type: SET_CATEGORIES,
  payload: data,
});
